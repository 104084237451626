<template>
  <Button :label="label" icon="ti-close" class="p-button-warning p-button-raised action-button"/>
</template>

<script>
export default {
  name: 'RemoveButton',
  props: {
    label: String
  }
}
</script>

<style scoped>
.action-button {
  margin: 0 3px;
}
</style>