<template>
  <Toolbar v-if="car" class="p-mb-4 p-mt-4">
    <template #start>
      <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem" />
    </template>
    <template #end>
      <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
    </template>
  </Toolbar>
  <Spinner v-if="isLoading"></Spinner>
  <DesktopDataTable else
                    class="p-mt-4"
                    tableName="Car offers"
                    :dataTableIsLoading="dataTableIsLoading"
                    :itemsArrayIsEmpty="itemsArrayIsEmpty"
                    :sort="sort"
                    :showSearchInput="false"
                    :actionButtonsCount="actionButtonsCount"
                    @sort-items="sortItems"
                    :headers="[
              {name: 'number', title: 'Number',  width: '10%', sortable: true},
              {name: 'customer', title: 'Customer',  width: '20%', sortable: false},
              {name: 'offer', title: 'Offer data',  width: '22%', sortable: false},
              {name: 'masters', title: 'Masters',  width: '12%', sortable: false},
              {name: 'grand_total', title: 'Amount',  width: '9%', sortable: true},
              {name: 'state', title: 'State',  width: '14%', sortable: true},
              {name: '', title: 'Order',  width: '9%', sortable: false},
              {name: 'valid_until', title: 'Valid until',  width: '10%', sortable: true},
              {name: '', title: '',  width: '45px', sortable: false},
          ]">
    <template v-slot:body>
      <template v-for="(item, index) of items" :key="index">
        <tr :class="{'updated-item': isItemUpdated(item.id)}">
          <td>
            <div>{{ item.number }}</div>
            <div v-if="item.parentOrder?.number" class="p-pt-1" style="font-size: 0.85em">
              <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">
                <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>
                <span>{{ item.parentOrder.number }}</span>
              </router-link>
            </div>
          </td>
          <td>
            <TableCustomerData :customer="item.customer" :showPhoneNumber="false" @change-customer-status="changeCustomerStatus"/>
          </td>
          <td>
            <div>
              <a href="#" @click.prevent="toggleShowPositionTable(item)">
                <i style="font-size: 0.9em" class="p-mr-1 pi" :class="item.showPositionsTable ? 'pi-minus': 'pi-plus'"></i>
                <span class="text">{{ item.name || item.number }}</span>
              </a>
            </div>
            <div class="table-small-text p-mt-1">
              {{ $t('Created') }}:
              <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>
              <span v-if="item.creator" class="table-date-line">
                <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>
                <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
              </span>
            </div>
          </td>
          <td class="avatar-td">
            <AvatarsCell :masters="item.masters"/>
          </td>
          <td>
            <span>{{ formatCurrency(item.grand_total) }}</span>
          </td>
          <td>
            <OfferStateButton :item="item"/>
          </td>
          <td>
            <Button v-if="item.state === 3" :disabled="(disableEditButton && isItemUpdated(item.id)) || !allDataLoaded" @click="createOrder(item)" v-tooltip.top="'Create order'" class="p-button-outlined p-button-sm p-d-flex p-ai-center p-jc-center" style="width: 40px;">
              <i class="ti-plus"></i>
              <i class="ti-hummer"></i>
            </Button>
            <span v-else-if="item.childOrder?.number">
              <router-link :to="{ path: `/orders/${item.childOrder.number}` }" target="_blank">{{ item.childOrder.number }}</router-link>
            </span>
          </td>
          <td>
            <span v-if="item.valid_until" :class="{'warning-color': countOverdueDays(item.valid_until) <= 0  && item.state !== 5 && item.state !== 7 }">{{ formatDay(item.valid_until) }}</span>
          </td>
          <td>
            <GetOfferPdfButtonSM :item="item" :disableEditButton="disableEditButton" :isItemUpdated="isItemUpdated(item.id)"/>
          </td>
          <td>
            <div class="p-d-flex">
              <EditButton :disabled="(disableEditButton && isItemUpdated(item.id)) || !allDataLoaded" @click="editItem(item)" />
  <!--              <DeleteButton @click="confirmItemDelete(item)"-->
  <!--                            v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
            </div>
          </td>
        </tr>
        <tr v-show="item.showPositionsTable" class="table-opened-info">
          <td colspan="12" style="padding: 0 !important; margin-bottom: 20px !important;">
            <PositionsTable :item="item" :isSpoilerTable="true" :isOffer="true" @update-items="updateItems"/>
          </td>
        </tr>
      </template>
    </template>
  </DesktopDataTable>

  <OwnPaginator v-show="items && items.length"
                :rows="pagination.perPage"
                :currentPage="pagination.currentPage - 1"
                :totalPages="pagination.pageCount"
                :totalRecords="pagination.totalCount"
                :rowsPerPageOptions="[5,10,25,50,100,200]"
                :pageLinkSize="11">
  </OwnPaginator>

  <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

  <OfferModal :visible="itemModal"
              :item="item"
              :masters="masters"
              :responsibles="responsibles"
              :taxes="taxes"
              :services="services"
              :suppliers="suppliers"
              :warehouseItems="warehouseItems"
              @update-items="updateItems"
              @update-item="updateOffer"
              @close="closeItemModal">
  </OfferModal>

  <OrderModal :visible="orderModal"
              :item="orderData"
              :masters="masters"
              :responsibles="responsibles"
              :taxes="taxes"
              :services="services"
              :suppliers="suppliers"
              :areas="areas"
              :warehouseItems="warehouseItems"
              @update-items="updateItems"
              @close="closeOrderModal">
  </OrderModal>

</template>

<script>
import httpClient from '@/services/http.services'
import OrderModal from '@/pages/orders/components/OrderModal'
import OfferModal from '@/pages/offers/components/OfferModal'
import OfferStateButton from '@/pages/offers/components/OfferStateButton'
import PositionsTable from '@/components/PositionsTable'
import calculateMixins from '@/mixins/calculateMixins'
import formatMixins from "@/mixins/formatMixins";
import httpMixins from "@/mixins/httpMixins";
import TableCustomerData from "@/components/TableCustomerData";
import datatableMixins from "@/mixins/datatableMixins";
import GetOfferPdfButtonSM from "@/pages/offers/components/GetOfferPdfButtonSM";
import AvatarsCell from "@/components/AvatarsCell";

export default {
  mixins: [ formatMixins, httpMixins, calculateMixins, datatableMixins ],
  components: { OrderModal, OfferModal, OfferStateButton, PositionsTable, TableCustomerData, GetOfferPdfButtonSM, AvatarsCell },
  emits: ['update-items', 'change-customer-status'],
  props: {
    car: Object,
  },
	data() {
		return {
      // warehouseItems: null,
      // masters: null,
      // responsibles: null,
      // taxes: null,
      // services: null,
      // suppliers: null,
      // areas: null,
      orderModal: false,
      orderData: {},
      fieldsExpand: 'responsible,customer,customer.customerStatus,creator,masters,positions,positions.history,consumable,car,comments_history,state_history,parentOrder,childOrder',
      apiLink: 'offer/get-car-offers',
      // sort: {
      //   column: 'id',
      //   direction: '-',
      // },
		}
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('cars') === -1 || route.indexOf('offers') === -1) {
        return false
      }
      this.checkRouteQuery()
      this.getItems()
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.orderModal) {
          this.closeOrderModal()
        }
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    changeCustomerStatus(newStatus, customerId) {
      if (!customerId) return false
      const itemsWithCustomer = this.items?.filter(i => i.user_id === customerId)
      if (itemsWithCustomer?.length) {
        itemsWithCustomer.forEach(item => {
          if (item.customer.customerStatus) {
            item.customer.customerStatus.status_id = newStatus
          } else {
            item.customer.customerStatus = {}
            item.customer.customerStatus.status_id = newStatus
          }
        })
      }
      this.$emit('change-customer-status', newStatus, customerId)
    },
    // updateOffer(item, summary, customer, car) {    // DODELAT'!!
    //   if (item.isNew) return false
    //   let filteredItem = this.items.filter(i => i.id === item.id)[0]
    //   // this.items = this.items.filter(i => i.id !== item.id)
    //   if (filteredItem) {
    //     // this.items.push(item)
    //     // console.log(filteredItem)
    //     // console.log(filteredItem.customer ,item.customer)
    //     filteredItem.name = item.name
    //     filteredItem.state = item.selectedState?.num
    //     filteredItem.customer = item.selectedCustomer ?? null
    //
    //     filteredItem.grand_total = +summary.grand_total
    //     filteredItem.customer = customer
    //     filteredItem.car = car
    //   }
    // },
    async updateOffer(id) {
      if (!id) return false
      // this.sendUpdateOfferWebSocket(id)

      let foundItem = this.items?.find(item => item.id === id)
      if (foundItem) {
        const indexOfFoundItem = this.items.indexOf(foundItem)
        if (indexOfFoundItem !== -1) {
          const itemData = await this.getOfferById(id)
          if (itemData) {
            this.items[indexOfFoundItem] = itemData
            this.updatedItemId = id
            // this.changeItemsWithSameUserOrCar(itemData)
            // this.constructOffersTable()
          }
        }
      }
      await this.getNewOffersCount()
    },
    async getOfferById(id) {
      try {
        const { data, status } = await httpClient.post(`offer/get-by-id?expand=${this.fieldsExpand}`, { id })
        if (status === 200 && data?.success && data.item) {
          return data.item
        } else {
          return null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.$store.commit('changeUpdatedOrderId', null)
      }
    },
    loadData() {
      if (!this.car) return false

      if (this.$route.params.id ) {
        this.checkRouteQuery()
        this.getItems()

        this.getModalData()

        // this.getMasters()
        // this.getResponsibles()
        // this.getTaxes()
        // this.getServices()
        // this.getSuppliers()
        // this.getAreas()
        // this.getWarehouseItems()
      }
    },
    createOrder(offer) {
      this.orderData = {
        ...offer,
        positions: offer.positions.filter(pos => pos.status === 1).map(p => ({ ...p, isNew: true })),
        id: null,
        state: 1,
        comments_history: null,
        history: null,
        customerDisabled: true,
        carDisabled: true,
        offerId: offer.id
      }

      if (this.itemModal) {
        this.itemModal = false
      }
      this.orderModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeOrderModal() {
      this.orderModal = false
      this.submitted = false
      this.$store.commit('toggleFirstLayer', false)
    },
    addNewItem() {
      this.item = {}
      if (this.car) {
        this.item.car = this.car
        this.item.carDisabled = true

        if (this.car.owner) {
          this.item.customer = this.car.owner
        }
      }

      this.submitted = false
      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    editItem(item) {
      this.item = { ...item }
      if (item.state === 5) {
        this.item.customerDisabled = this.item.carDisabled = true
      }
      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { carId: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: {
            carId: this.$route.params.id
          }
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (this.openedItemsIds.length) {
            this.items.forEach(item => {
              if (this.openedItemsIds.indexOf(item.id) !== -1) {
                item.showPositionsTable = true
              }
            })
          }

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.disableEditButton = false
        this.dataTableIsLoading = false
      }
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.masters && this.responsibles && this.taxes && this.services && this.suppliers && this.areas)
    },
    itemToDeleteData() {
      return 'delete data'
    },
    actionButtonsCount() {
      // if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin || this.$store.state.user.role === constants.userRoles.accountant) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 1
    },
  }
}
</script>