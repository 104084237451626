<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Confirm') }}</div>
    </template>
    <div class="p-formgrid p-grid">
      <div v-if="items?.length" class="p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center p-mb-4">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
<!--          <span>{{ $t('Are you sure you want to proceed?') }}</span>-->
          <div>Warning! Possible duplicates found:</div>
        </div>
        <div class="table-wrapper">
          <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows">
            <thead class="p-datatable-thead">
            <tr>
              <th>{{ $t('Plate number') }}</th>
              <th>{{ $t('Make') }}</th>
              <th>{{ $t('Model') }}</th>
              <th>{{ $t('Year') }}</th>
              <th>{{ $t('VIN number') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody class="p-datatable-tbody">
              <tr v-for="item of items" :key="item.id">
                <td>
                  <span v-if="item.plate_number">{{ item.plate_number }}</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                  <span v-if="item.make?.name">{{ item.make.name }}</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                  <span v-if="item.model?.name">{{ item.model.name }}</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                  <span v-if="item.year">{{ item.year }}</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                  <span v-if="item.vin">{{ item.vin }}</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                  <Button :label="$t('Select')"
                          @click="selectCar(item)"
                          class="p-button-raised"
                          style="width: auto;">
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="p-d-flex p-ai-center p-flex-wrap">
          <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3">
            <div class="p-d-flex p-ai-center">
              <InputSwitch id="create-new-car-anyway-switch" v-model="createNewAnyway"/>
            </div>
            <label for="create-new-car-anyway-switch" class="switch-label pointer">
              {{ carId ? 'Change data anyway' : 'Create new anyway'}}
            </label>
          </div>
<!--          <Button :disabled="!createNewAnyway"-->
          <Button v-if="createNewAnyway"
                  @click="createNew"
                  :label="carId ? 'Change' : $t('Create new')"
                  class="p-button-raised p-button-warning"
                  style="width: auto;"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>
</template>

<script>
export default {
  // name: 'ConfirmModal',
  emits: ['close', 'confirm', 'create-new-anyway', 'select-existing-car'],
  props: {
    items: Array,
    data: String,
    header: null,
    footer: null,
    visible: Boolean,
    style: Object,
    carId: {
      type: [ Number, null ],
      default: null
    }
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.createNewAnyway = false
      }
    }
  },
  data() {
    return {
      createNewAnyway: false,
    }
  },
  methods: {
    selectCar(car) {
      this.$emit('select-existing-car', car)
      this.close()
    },
    createNew() {
      this.$emit('create-new-anyway')
      this.close()
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
    confirm() {
      this.$emit('confirm')
    }
  },
  computed: {
    computedWindowSize() {
      // if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
      //   return {width: '85%'}
      // } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
      //   return {width: '550px'}
      // } else {
      //   return {width: '650px'}
      // }
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '35px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '95%', maxWidth: '750px', marginTop: '35px'}
      } else {
        return {position: 'absolute', top: 0, width: '750px', marginTop: '25px'}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-wrapper {
  overflow-y: auto;
}

.p-datatable {
  width: 100%;
  border-collapse: collapse;
}
</style>