<template>
<!--  <MessageBar v-if="itemData?.wasUpdatedByThirdParty" :text="'Внимание! Заказ был обновлен. Что-бы продолжить работу закройте его и откройте снова.'" :error="true"/>-->
  <MessageBar v-if="itemData?.wasUpdatedByThirdParty"
              :text="'Внимание! Заказ был обновлен.'"
              :severity="'warn'"/>

  <Modal :style="computedWindowSize"
         :visible="visible"
         ref="orderModal"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ itemData?.id ? 'Edit order ' + itemData?.number : 'New order' }}</div>
    </template>
    <MessageBar v-show="!dataIsSending && invoiceFinancialPeriodIsClosed"
                :text="'Warning! Financial period already closed.'"
                :fixedToTop="false"
                :severity="'warn'"/>

    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="order-customer"
                    :disabled="!!customerDisabled"
                    class="dropdown__select"
                    :class="{
                      'p-invalid' : submitted && (isAnyActiveServicePosition && !selectedCustomer),
                      'dropdown__select--border-radius': customerDisabled,
                      'dropdown__select--one-button-width': userCanEditCustomer && !customerDisabled,
                      'dropdown__select--two-buttons-width': userCanEditCustomer && !customerDisabled
                    }"
                    :searchData="customerSearchData"
                    v-model="selectedCustomer"
                    :options="customers"
                    filterPlaceholder="Search customer"
                    :emptyMessage="'Please enter 3 or more characters'"
                    :emptyFilterMessage="showCustomerMinCharsMessage ? 'Please enter 3 or more characters' : 'No results found'"
                    :filterFields="['first_name', 'last_name', 'full_phone_number', 'email', 'company_name', 'reg_number', 'company_full_phone_number', 'company_email']"
                    :filter="true"
                    :clearSearchData="!visible || clearCustomerSearchData"
                    :createButton="true"
                    :loading="customersDataIsLoading"
                    @change="changeCustomer"
                    @filter="searchCustomers"
                    @create-new="createCustomer"
                    :showClear="!customerDisabled">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="p-d-flex p-align-center">
                <CustomerStatusHistoryButton :customerStatus="slotProps.value.customerStatus" :isChangeable="false"/>
<!--                <i class="ti-user p-mr-2"></i>-->
                <div v-if="slotProps.value.type === constants.userTypes.legal_entity && slotProps.value.company_name">{{ slotProps.value.company_name }} ({{ slotProps.value.first_name }} {{ slotProps.value.last_name }})</div>
                <div v-else>{{ slotProps.value.first_name }} {{ slotProps.value.last_name }}</div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option" class="p-d-flex p-ai-center p-jc-between">
                <div class="p-d-flex p-align-center">
<!--                  <i class="ti-user p-mr-2"></i>-->
                  <CustomerStatusHistoryButton :customerStatus="slotProps.option.customerStatus" :isChangeable="false"/>
                  <span v-if="slotProps.option.type === constants.userTypes.legal_entity && slotProps.option.company_name">{{ slotProps.option.company_name }} ({{ slotProps.option.first_name }} {{ slotProps.option.last_name }})</span>
                  <span v-else>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</span>
                </div>
                <div class="p-ml-1" v-if="slotProps.option.type === constants.userTypes.legal_entity && slotProps.option.company_name"><span v-if="slotProps.option.company_phone_code">+{{ slotProps.option.company_phone_code }}</span> {{ slotProps.option.company_phone_number ?? '' }}</div>
                <div class="p-ml-1" v-else><span v-if="slotProps.option.phone_code">+{{ slotProps.option.phone_code }}</span> {{ slotProps.option.phone_number }}</div>
              </div>
            </template>
          </CustomDropdown>
          <div class="dropdown__buttons" v-show="!customerDisabled">
<!--            <Button v-if="selectedCustomer && (selectedCustomer.role === constants.userRoles.customer || $store.state.user.role === constants.userRoles.superAdmin) && selectedCustomer.role === constants.userRoles.customer" @click="editCustomer" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center"><i class="ti-pencil"></i></Button>-->
            <Button v-if="userCanEditCustomer" @click="editCustomer" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center"><i class="ti-pencil"></i></Button>
            <Button @click="createCustomer" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
          <label for="order-customer">Customer<span v-show="isAnyActiveServicePosition" class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && (isAnyActiveServicePosition && !selectedCustomer)">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="order-car"
                          :disabled="!!carDisabled"
                          class="dropdown__select"
                          :class="{'dropdown__select--border-radius': carDisabled || (!selectedCar && !selectedCustomer), 'dropdown__select--one-button-width': ((selectedCustomer && !selectedCar) || (!selectedCustomer && selectedCar)) && !carDisabled, 'dropdown__select--two-buttons-width': selectedCar && selectedCustomer && !carDisabled }"
                          :searchData="carSearchData"
                          v-model="selectedCar"
                          :options="cars"
                          filterPlaceholder="Search car"
                          :emptyMessage="'Please enter 3 or more characters'"
                          :emptyFilterMessage="showCarMinCharsMessage ? 'Please enter 3 or more characters' : 'No results found'"
                          :filterFields="['plate_number', 'vin', 'model']"
                          :filter="true"
                          :createButton="!!selectedCustomer"
                          :clearSearchData="!visible || clearCarSearchData"
                          :loading="carsDataIsLoading"
                          @change="changeCar"
                          @filter="searchCars"
                          @create-new="createCar"
                          :showClear="!carDisabled">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div class="p-d-flex p-ai-center">
                  <div>
                    <i class="ti-car p-mr-2"></i>
                    <span v-if="slotProps.value.make">{{ slotProps.value.make.name }}</span> <span v-if="slotProps.value.model">{{ slotProps.value.model.name }}</span><span v-if="slotProps.value.plate_number">, {{ slotProps.value.plate_number }}</span>
                  </div>
                </div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option" class="p-d-flex p-ai-center p-jc-between">
                <div>
                  <i class="ti-car p-mr-2"></i>
                  <span v-if="slotProps.option.make">{{ slotProps.option.make.name }}</span> <span v-if="slotProps.option.model">{{ slotProps.option.model.name }}</span><span v-if="slotProps.option.year">, {{ slotProps.option.year }} </span><span v-if="slotProps.option.vin">, {{ slotProps.option.vin }}</span>
                </div>
                <div class="p-ml-1" v-if="slotProps.option.plate_number">{{ slotProps.option.plate_number }}</div>
              </div>
            </template>
          </CustomDropdown>
          <div class="dropdown__buttons" v-show="!carDisabled">
            <Button v-if="selectedCar" @click="editCar" class="dropdown__edit-button p-d-flex p-ai-center p-jc-center" :class="{'dropdown__edit-button--rounded': selectedCar && !selectedCustomer}"><i class="ti-pencil"></i></Button>
            <Button v-if="selectedCustomer" @click="createCar" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
          <label for="order-car">Car <span v-if="customerCars?.length">({{ customerCars.length }})</span></label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @change="changeOrderState"-->
<!--          :disabled="(itemData.id && itemData.state === 1) || itemData.state === 9 || itemData.state === 10 || itemData.state === 12 || editingIsDisabled || orderStateDropdownIsDisabled"-->
          <CustomDropdown id="order_state"
                          :class="{'p-invalid' : submitted && !itemData.selectedState}"
                          :disabled="itemData.state === 9 || itemData.state === 10 || itemData.state === 12 || editingIsDisabled || orderStateDropdownIsDisabled"
                          @change="calculateRemindAppointmentState"
                          v-model="itemData.selectedState"
                          :options="OrderStates"
                          :preventFilter="true"
                          :filter="false"
                          :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span class="state-badge" :class="slotProps.value.color">{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span class="state-badge" :class="slotProps.option.color">{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="order_state">State<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.selectedState">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12§ p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @input="changeStartDate"-->
<!--          @blur="checkStartDateIsValid"-->
          <Calendar
              :showTime="true"
              @show="toogleDropdownOverlayVisibility(true)"
              @hide="toogleDropdownOverlayVisibility(false)"
              @date-select="changeStartDate"
              :disabled="editingIsDisabled || orderStartDateIsDisabled"
              :class="{'p-invalid' : (itemData.start && !startDateIsValid) || (submitted && !itemData.start)}"
              :showButtonBar="true"
              :stepMinute="10"
              :dateFormat="settings.dateFormat"
              id="order_start"
              v-model="itemData.start"
              :showIcon="true"
              :selectOtherMonths="true"
              autocomplete="off"/>
          <!--          <label for="order_start" :class="{'warning-color' : !startDateIsValid || (submitted && !itemData.start)}">Order start<span class="warning-color">*</span></label>-->
          <label for="order_start">Order start<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.start">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="itemData.start && !startDateIsValid">{{ $t('Invalid date format') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          :class="{'p-invalid' : submitted && !selectedResponsible}"-->
          <CustomDropdown id="responsible"
                          v-model="selectedResponsible"
                          :clearSearchData="!visible"
                          :disabled="editingIsDisabled || responsibleDropdownIsDisabled"
                          :options="responsibles"
                          optionLabel="full_name"
                          :filter="true"
                          :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.full_name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.full_name }}</span>
              </div>
            </template>
          </CustomDropdown>
<!--          <label for="responsible">Responsible<span class="warning-color">*</span></label>-->
          <label for="responsible">Responsible</label>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !selectedResponsible">{{ $t('Required field') }}</small>-->
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <MultiSelect @change="changeSelectedMasters"
                       id="masters"
                       @show="toogleDropdownOverlayVisibility(true)"
                       @hide="toogleDropdownOverlayVisibility(false)"
                       v-model="selectedMasters"
                       :clearSearchData="!visible"
                       :disabled="editingIsDisabled || selectedMastersDropdownIsDisabled"
                       :options="mastersData"
                       optionLabel="full_name"
                       :filter="true"
                       display="chip"/>
          <label for="masters">Masters</label>
        </div>
      </div>
      <!--      //Name don't delete!!!!-->
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <InputText id="order_name"
                     :disabled="editingIsDisabled"
                     v-model.trim="itemData.name"
                     autocomplete="off"/>
          <label for="order_name">Name</label>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !itemData.name">{{ $t('Required field') }}</small>-->
      </div>
      <div class="p-field p-col-6 p-sm-6 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="order_area"
                              v-model="selectedArea"
                              :clearSearchData="!visible"
                              :options="areas"
                              :disabled="editingIsDisabled"
                              optionLabel="name"
                              :filter="true"
                              :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }} <span v-if="slotProps.value.description">({{ slotProps.value.description }})</span></span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }} <span v-if="slotProps.option.description">({{ slotProps.option.description }})</span></span>
              </div>
            </template>
          </CustomDropdown>
          <label for="order_area">{{ $t('Working area')}}</label>
        </div>
      </div>
      <div class="p-field p-col-6 p-sm-6 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="order_mileage" :locale="computedNumberInputLocale" :disabled="editingIsDisabled" v-model="itemData.mileage" autocomplete="off"/>
          <label for="order_mileage">Mileage ({{ $t(settings.measurements.mileage) }})</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 add-position-buttons">
        <Button :disabled="editingIsDisabled || positionsEditingIsDisabled" @click="addNewPosition(1)" v-tooltip.top="'Add service'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-hummer "></i>
        </Button>
        <Button :disabled="editingIsDisabled || positionsEditingIsDisabled" @click="addNewPosition(2)" v-tooltip.top="'Add request'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-truck"></i>
        </Button>
        <Button :disabled="editingIsDisabled || positionsEditingIsDisabled" @click="addNewPosition(3)" v-tooltip.top="'Add warehouse item'"  class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-shopping-cart"></i>
        </Button>
        <Button :disabled="editingIsDisabled || positionsEditingIsDisabled" @click="addNewPosition(4)" v-tooltip.top="'Add free position'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-pencil"></i>
        </Button>
        <Button :disabled="editingIsDisabled || positionsEditingIsDisabled" @click="addMaintenancePositions" v-tooltip.top="'Add maintenance (oil, filters)'" class="p-button-outlined add-position-button--text">
          <i class="ti-plus p-mr-1"></i>
          <span>{{ $t('Maintenance') }} 1</span>
        </Button>
        <Button :disabled="editingIsDisabled || positionsEditingIsDisabled" @click="addSecondMaintenancePositions" v-tooltip.top="'Add maintenance (brakes)'" class="p-button-outlined add-position-button--text p-mr-5">
          <i class="ti-plus p-mr-1"></i>
          <span>{{ $t('Maintenance') }} 2</span>
        </Button>

<!--        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3" :class="{'p-mr-3' : settings.useSmsService}">-->
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3">
          <div class="p-d-flex p-ai-center">
            <InputSwitch :disabled="editingIsDisabled || positionsEditingIsDisabled"
                         id="use_consumables_order_modal"
                         v-model="useConsumables"
                         @change="toggleConsumable"/>
          </div>
          <label for="use_consumables_order_modal" class="switch-label" :class="{'pointer': !editingIsDisabled && !positionsEditingIsDisabled}">Use consumables</label>
        </div>
<!--        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3" v-if="computedDeletedPositionsExists">-->
<!--          <div>-->
<!--            <InputSwitch id="show_deleted_order_modal" v-model="showDeleted"/>-->
<!--          </div>-->
<!--          <label for="show_deleted_order_modal" class="switch-label pointer">Show Deleted</label>-->
<!--        </div>-->
<!--        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" :class="{'p-mr-3' : settings.useSmsService}">-->
<!--          <div>-->
<!--            <InputSwitch id="generate_task_order_order_modal" v-model="generateTaskOrder"/>-->
<!--          </div>-->
<!--          <label for="generate_task_order_order_modal" class="switch-label pointer">Generate task order</label>-->
<!--        </div>-->
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3" v-show="settings.useSmsService">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="remind_appointment_order_modal" @change="toggleRemindAppointment" :disabled="isRemindAppointmentDisabled" v-model="remindAppointment"/>
          </div>
          <label for="remind_appointment_order_modal" class="switch-label" :class="{'pointer':!isRemindAppointmentDisabled}">Remind appointment</label>
        </div>

        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3 p-mr-3" v-show="!noPositions">
          <div class="p-d-flex p-ai-center">
            <InputSwitch :disabled="editingIsDisabled || positionsEditingIsDisabled" id="is_warranty_order_modal" @change="toggleWarrantyOrder" v-model="isWarrantyOrder"/>
          </div>
          <label for="is_warranty_order_modal" class="switch-label" :class="{'pointer': !editingIsDisabled && !positionsEditingIsDisabled}">Warranty</label>
        </div>
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" v-show="!noPositions">
          <div class="p-d-flex p-ai-center">
            <InputSwitch :disabled="editingIsDisabled || positionsEditingIsDisabled || (isAnyPositionWithDiscount && showDiscountColumn)" id="show-discount-toggler-order-modal" v-model="showDiscountColumn"/>
          </div>
          <label for="show-discount-toggler-order-modal" class="switch-label" :class="{'pointer': !editingIsDisabled && !positionsEditingIsDisabled && (!isAnyPositionWithDiscount || (isAnyPositionWithDiscount && !showDiscountColumn))}">Show discount</label>
        </div>
      </div>

<!--      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12" :class="{' p-mb-5' : !showPositionsTable}">-->
<!--        <div v-if="computedDeletedPositionsExists" style="background-color: red">-->
<!--          <a v-if="showDeleted" @click.prevent="showDeleted = false" class="p-link">Hide deleted</a>-->
<!--          <a v-else-if="!showDeleted" @click.prevent="showDeleted = true" class="p-link">Show deleted</a>-->
<!--        </div>-->
<!--      </div>-->

      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 table-wrapper" :class="{'p-mb-5' : showPositionsTable || computedDeletedPositionsExists }">
        <div v-if="computedDeletedPositionsExists">
          <a @click.prevent="showDeleted = !showDeleted" class="p-link">
            <i style="font-size: 0.9em" class="pi p-mr-1" :class="showDeleted ? 'pi-minus' :'pi-plus'"></i>
            <span>{{ showDeleted ? 'Hide deleted' : 'Show deleted' }}</span>
          </a>
        </div>
        <table v-if="showPositionsTable" class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-thead">
            <tr>
              <th class="table-title__icon"></th>
              <th class="table-title__name--first"></th>
              <th class="table-title__name--second"></th>
              <th class="table-title__name--last">{{$t('Name')}}</th>
              <th class="table-title__qty">{{$t('Qty')}}</th>
              <th class="table-title__purchase">Buy</th>
              <th class="table-title__sell">Sell</th>
              <th class="table-title__discount" v-show="showDiscountColumn">Dis.(%)</th>
              <th class="table-title__margin" v-show="showMarginColumn">Mgn.%</th>
              <th class="table-title__sum">Sum</th>
              <th class="table-title__tax">Tax</th>
              <th class="table-title__total">Total</th>
              <th v-if="isWarrantyOrder">
                <div class="p-d-flex p-ai-center" style="position: relative; left: 3px" v-tooltip.top="'Is warranty'">
                  <i class="pi pi-info-circle"></i>
                </div>
              </th>
              <th class="table-title__request-state">
                <div v-show="!positionsEditingIsDisabled">
                  <a v-if="computedActiveUnorderedRequests.length"
                     @click.prevent="changeStatesToOrdered"
                     style="text-align: center;"
                     class="p-p-2 pointer"
                     v-tooltip.top="'All items are ordered'">
                    <i class="p-link pi pi-check-square"></i>
                  </a>
                  <a v-else-if="changeStatesToOrderedIsClicked && thereIsPositionWithChangedMultipleState"
                     @click.prevent="undoChangeStatesToOrdered"
                     style="text-align: center;"
                     class="p-p-2 pointer"
                     v-tooltip.top="'Undo'">
                    <i class="p-link pi pi-history"></i>
                  </a>
                </div>
              </th>
              <th class="table-title__info"></th>
              <th class="table-title__info"></th>
<!--              <th class="table-title__info"></th>-->
  <!--            <th class="table-title__invoice"></th>-->
              <th class="table-title__action-btn" v-if="!editingIsDisabled && !positionsEditingIsDisabled"></th>
            </tr>
          </thead>
          <tbody class="p-datatable-tbody table dropzone">
<!--            <tr v-for="(position, index) of positions" :key="index" draggable="true" @dragstart="dragOnStart" @dragover="dragOnOver">-->
            <tr v-for="(position, index) of positions"
                :key="index">
<!--              @mousedown="rowOnMouseDown"-->
              <template v-if="position.status || showDeleted">
                <td :data-index="index"
                    draggable="true"
                    @dragstart="handleDragStart"
                    @dragenter="handleDragEnter"
                    @dragleave="handleDragLeave"
                    @dragover="handleDragOver"
                    @drop="handleDrop"
                    class="grabbable" :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
<!--                  <div class="p-d-flex p-ai-center">-->
<!--                    <i class="grabbable p-mr-2" :class="{'ti-hummer':position.type === 1, 'ti-truck':position.type === 2, 'ti-shopping-cart':position.type === 3, 'ti-pencil':position.type === 4}"></i>-->
<!--                    <div class="p-d-flex p-flex-column">-->
<!--                      <i class="pi pi-angle-up"></i>-->
<!--                      <i class="pi pi-angle-down"></i>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="p-d-flex p-ai-center grabbable" >
                    <div class="p-mr-2 grabbable" v-if="position.sorting">{{ position.sorting }}</div>
                    <i class="grabbable" :class="{'ti-hummer':position.type === 1, 'ti-truck':position.type === 2, 'ti-shopping-cart':position.type === 3, 'ti-pencil':position.type === 4}"></i>
                  </div>
<!--                  <div style="position: absolute; margin-left: 120px" class="grabbable p-ml-2" v-if="position.name">{{ position.name }}</div>-->
<!--                  <i class="grabbable" :class="{'ti-hummer':position.type === 1, 'ti-truck':position.type === 2, 'ti-shopping-cart':position.type === 3, 'ti-pencil':position.type === 4}"></i>-->
                </td>
                <template v-if="position.type === 1">
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
                    <CustomDropdown :class="{'p-invalid warning-background' : submitted && !position.selectedService}"
                                    :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled"
                                    class="table-body__name-input--first"
                                    v-model="position.selectedService"
                                    @change="changeService(position)"
                                    @filter="searchServices($event, position)"
                                    placeholder="Select service"
                                    :options="position.servicesData || servicesData"
                                    :filter="true"
                                    optionLabel="name"
                                    :showClear="false">
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                          <span>{{ slotProps.value.name }}</span>
                        </div>
                        <span v-else>Select service</span>
                      </template>
                      <template #option="slotProps">
                        <div>
                          <span>{{ slotProps.option.name }}</span>
                        </div>
                      </template>
                    </CustomDropdown>
                  </td>
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
<!--                    <CustomDropdown :disabled="position.status === 0 || editingIsDisabled || !selectedMasters?.length"-->
                    <CustomDropdown :disabled="position.status === 0 || editingIsDisabled || !selectedMasters?.length || positionsEditingIsDisabled"
                                    class="table-body__name-input--code"
                                    v-model="position.selectedMaster"
                                    @change="changeMaster(position)"
                                    placeholder="Select master"
                                    :options="selectedMasters"
                                    :filter="false"
                                    optionLabel="full_name"
                                    :showClear="true">
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                          <span>{{ slotProps.value.full_name }}</span>
                        </div>
                        <span v-else>Select master</span>
                      </template>
                      <template #option="slotProps">
                        <div>
                          <span>{{ slotProps.option.full_name }}</span>
                        </div>
                      </template>
                    </CustomDropdown>
                  </td>
                </template>
                <template v-else-if="position.type === 2">
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                    <div class="dropdown-wrapper table-body__name-input--first">
<!--                      'dropdown__select&#45;&#45;one-button-width' : position.status !== 0 && !position.fixed_qty || userIsAdminOrAccountant}"-->
                      <CustomDropdown class="dropdown__select"
                                      :class="{'p-invalid warning-background' : submitted && !position.selectedSupplier,
                                      'dropdown__select--border-radius' : editingIsDisabled || positionsEditingIsDisabled || ((position.status === 0 || !!position.fixed_qty) && !userIsAdminOrAccountant),
                                      'dropdown__select--one-button-width' : editingIsDisabled || positionsEditingIsDisabled ? userIsAdminOrAccountant : userIsAdminOrAccountant ? true : position.status !== 0 && !position.fixed_qty }"
                                      :searchData="position.searchData"
                                      :disabled="editingIsDisabled || positionsEditingIsDisabled || ((position.status === 0 || !!position.fixed_qty) && !userIsAdminOrAccountant)"
                                      v-model="position.selectedSupplier"
                                      :options="position.suppliersData || suppliersData"
                                      filterPlaceholder="Find supplier"
                                      optionLabel="company_name"
                                      :filterFields="['name', 'company_name', 'reg_number']"
                                      :filter="true"
                                      :createButton="false"
                                      @change="changeSupplier(position)"
                                      @filter="searchSuppliers($event, position)"
                                      placeholder="Select supplier"
                                      :showClear="false">
                        <template #value="slotProps">
                          <div v-if="slotProps.value">
                              <div>{{ slotProps.value.company_name }} <span v-if="slotProps.value.name">({{ slotProps.value.name }})</span></div>
  <!--                          </div>-->
                          </div>
                          <span v-else>Select supplier</span>
                        </template>
                        <template #option="slotProps">
                          <div>{{ slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>
                        </template>
                      </CustomDropdown>
<!--                      <Button v-if="position.isNew && position.status !== 0 || (!position.isNew && position.state <= 3 && position.status !== 0) || position.changedMultipleState" @click="addNewSupplier(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>-->
<!--                      <Button v-if="(position.status !== 0 && !position.fixed_qty) || (userIsAdminOrAccountant && (!editingIsDisabled || !positionsEditingIsDisabled))" @click="addNewSupplier(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>-->
                      <Button v-if="editingIsDisabled || positionsEditingIsDisabled ? userIsAdminOrAccountant : userIsAdminOrAccountant ? true : position.status !== 0 && !position.fixed_qty"
                              @click="addNewSupplier(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
                    </div>
                  </td>
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                    <InputText :placeholder="$t('Code')" :disabled="(position.fixed_qty || position.status === 0) && !userIsAdminOrAccountant || editingIsDisabled || positionsEditingIsDisabled" v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                  </td>
                </template>
                <template v-if="position.type === 3">
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
                    <div class="dropdown-wrapper table-body__name-input--first">
                      <CustomDropdown class="dropdown__select"
                                          :class="{
                                            'p-invalid warning-background' : submitted && !position.selectedWarehouseItem,
                                            'dropdown__select--border-radius' : position.status === 0 || editingIsDisabled || positionsEditingIsDisabled || !userCanAddNewWarehouseItem,
                                            'dropdown__select--one-button-width' : position.status !== 0 && !editingIsDisabled && !positionsEditingIsDisabled && userCanAddNewWarehouseItem }"
                                          :searchData="position.searchData"
                                          :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled"
                                          v-model="position.selectedWarehouseItem"
                                          @change="changeWarehouseItem(position)"
                                          @filter="searchWarehouseItems($event, position)"
                                          placeholder="Select item"
                                          :options="position.warehouseItemsData || warehouseItemsData"
                                          :filter="true"
                                          :filterFields="['code', 'raw_code', 'name']"
                                          :showClear="false">
                        <template #value="slotProps">
                          <div v-if="slotProps.value">
                            <span>{{ slotProps.value.name }}</span>
                          </div>
                          <span v-else>Select item</span>
                        </template>
                        <template #option="slotProps">
                          <div>
                            <div>{{ slotProps.option.name }}<span v-if="slotProps.option.code">, Code: {{ slotProps.option.code }}</span></div>
                            <span class="p-mt-1" style="font-size: 0.9rem">Qty: {{ formatDecimal(slotProps.option.qty) }}, Reserved: {{ formatDecimal(slotProps.option.reserved_qty) }}, Available: {{ formatDecimal(slotProps.option.qty - slotProps.option.reserved_qty) }}</span>
                          </div>
                        </template>
                      </CustomDropdown>
                      <Button v-if="position.status !== 0 && !editingIsDisabled && !positionsEditingIsDisabled && !positionsEditingIsDisabled && userCanAddNewWarehouseItem" @click="addNewWarehouseItem(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
                    </div>
                  </td>
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">
                    <InputText :placeholder="$t('Code')" disabled v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                  </td>
<!--                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'inactive-position-td': position.status === 2 }">-->
<!--                    <InputText :disabled="!position.selectedWarehouseItem || position.status === 0 || editingIsDisabled || positionsEditingIsDisabled"-->
<!--                               v-model.trim="position.name"-->
<!--                               :placeholder="$t('Name')"-->
<!--                               @input="updatePosition(position, false, 'name')"-->
<!--                               :class="{'p-invalid' : submitted && position.selectedWarehouseItem && !position.name}"-->
<!--                               class="table-body__name-input&#45;&#45;name"-->
<!--                               autocomplete="off"/>-->
<!--                  </td>-->
                </template>


                <template v-if="position.type === 4">
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                    <InputText disabled class="table-body__name-input--first"></InputText>
                  </td>
                  <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                    <InputText :placeholder="$t('Code')" :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled" v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                  </td>
                </template>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td':  position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <InputText :placeholder="$t('Name')"
                             :disabled="(position.type === 1 && !position.selectedService) ||
                                        (position.type === 3 && !position.selectedWarehouseItem) ||
                                        position.status === 0 ||
                                        editingIsDisabled ||
                                        positionsEditingIsDisabled"
                             v-model.trim="position.name"
                             @input="updatePosition(position, false, 'name')"
                             :class="{'p-invalid warning-background' : submitted && !position.name}"
                             class="table-body__name-input--name"
                             autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
<!--                  <InputNumber v-if="position.type === 2"-->
<!--                               :locale="computedNumberInputLocale"-->
<!--                               :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled || (!position.isNew && (position.state >= 4)) && !position.changedMultipleState"-->
<!--                               @input="updatePosition(position, $event.value, 'qty', 999, 0)"-->
<!--                               v-model="position.qty"-->
<!--                               :inputClass="{'p-invalid' : submitted && !position.qty && position.status !== 0}"-->
<!--                               class="table-body__qty-input"-->
<!--                               :min="0"-->
<!--                               :max="999"-->
<!--                               :showButtons="(position.isNew && position.status !== 0) || (!position.isNew && position.state <= 3 && position.status !== 0) || position.changedMultipleState"-->
<!--                               autocomplete="off"/>-->
                  <InputNumber v-if="position.type === 2"
                               :locale="computedNumberInputLocale"
                               :disabled="(position.status === 0
                               || editingIsDisabled
                               || positionsEditingIsDisabled
                               || (!position.isNew && (position.state >= 4))
                               && !position.changedMultipleState) && !userIsAdminOrAccountant"
                               @input="updatePosition(position, $event.value, 'qty', 99999, 0)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid warning-background' : submitted && !position.qty && position.status !== 0}"
                               class="table-body__qty-input"
                               :min="0"
                               :max="99999"
                               :showButtons="(!positionsEditingIsDisabled &&
                               ((position.isNew && position.status !== 0) ||
                               (!position.isNew && position.state <= 3 && position.status !== 0)
                               || position.changedMultipleState)) || userIsAdminOrAccountant"
                               autocomplete="off"/>
<!--                  :min="calculatedMinQty(position.type)"-->
                  <InputNumber v-else
                               :locale="computedNumberInputLocale"
                               :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled"
                               @input="updatePosition(position, $event.value, 'qty', 99999, -99999)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid warning-background' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               mode="decimal"
                               :min="-99999"
                               :max="99999"
                               :showButtons="position.status !== 0 && !editingIsDisabled && !positionsEditingIsDisabled"
                               :minFractionDigits="position.type === 3 && !position.is_decimal ? 0 : 2"
                               :maxFractionDigits="position.type === 3 && !position.is_decimal ? 0 : 2"
                               autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
<!--                  :inputClass="{'p-invalid warning-background' : submitted && (position.sell_price < position.purchase_price)}"-->
                  <InputNumber @input="updatePosition(position, $event.value, 'purchase_price', 1000000, 0)"
                               :locale="computedNumberInputLocale"
                               :disabled="editingIsDisabled || positionsEditingIsDisabled || (position.type === 1 || position.type === 3 || position.status === 0) || (position.type === 2 && +position.fixed_qty > 0)"
                               v-model="position.purchase_price" class="table-body__purchase-input"
                               :inputClass="{'p-invalid warning-background' : position.sell_price && +position.sell_price && position.sell_price < position.purchase_price}"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="2"
                               :maxFractionDigits="4"
                               autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <InputNumber @input="updatePosition(position, $event.value, 'sell_price',  1000000, 0)"
                               :locale="computedNumberInputLocale"
                               :disabled="editingIsDisabled || positionsEditingIsDisabled || (position.type === 3 && !position.selectedWarehouseItem) || position.type === 1 || position.status === 0"
                               v-model="position.sell_price"
                               :inputClass="{'p-invalid warning-background' : (submitted && !position.sell_price) || (position.sell_price && +position.sell_price && position.sell_price < position.purchase_price)}"
                               class="table-body__sell-input"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="2"
                               :maxFractionDigits="4"
                               autocomplete="off"/>
                </td>
                <td v-show="showDiscountColumn" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <InputNumber :locale="computedNumberInputLocale"
                               @input="updatePosition(position, $event.value, 'discount', 100, 0)"
                               :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled"
                               v-model="position.discount"
                               class="table-body__discount-input"
                               mode="decimal"
                               :min="0"
                               :max="100"
                               :minFractionDigits="2"
                               :maxFractionDigits="2"
                               autocomplete="off"/>
                </td>
                <td v-show="showMarginColumn"
                    :class="{
                      'new-item' : position.isNew,
                      'deleted-position-td': position.status === 0,
                      'updated-item': position.isReduced || position.changedMultipleState,
                      'inactive-position-td': position.status === 2
                    }">
                  <InputNumber :locale="computedNumberInputLocale"
                               v-show="position.type !== 1"
                               @input="marginOnInput(position, $event.value)"
                               :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled || (position.discount && +position.discount !== 0)"
                               v-model="position.margin"
                               class="table-body__margin-input"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="0"
                               :maxFractionDigits="2"
                               autocomplete="off"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <InputNumber :locale="computedNumberInputLocale" v-model="position.sum" mode="decimal" class="table-body__sum-input" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <CustomDropdown @change="changeTax(position, $event.value)"
                            class="table-body__tax-input"
                            :class="{'p-invalid warning-background' : submitted && !position.selectedTax}"
                            :disabled="position.status === 0 || editingIsDisabled || positionsEditingIsDisabled"
                            v-model="position.selectedTax"
                            :options="taxesData"
                            :filter="false"
                            optionLabel="value"
                            :showClear="false">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.value }}%</span>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                      </div>
                    </template>
                  </CustomDropdown>
<!--                  {{ formatDecimal(position.tax_value) }}%-->
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <InputNumber :locale="computedNumberInputLocale" v-model="position.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
                </td>
                <td v-if="isWarrantyOrder" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2}">
                  <Checkbox :disabled="editingIsDisabled || positionsEditingIsDisabled" @change="toggleWarrantyPosition(position)" v-if="position.status === 1" class="p-mr-1" id="form-agree-inputswitch" :binary="true" v-model="position.is_warranty"/>
                </td>
                <td :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <RequestPositionStateButton :position="position" @update-request-state-history="updateRequestStateHistory" @update-items="updateItems" :isModal="true"/>
                </td>
                <td class="p-p-0" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <PositionHistoryButton :position="position"/>
                </td>
                <td class="p-p-0" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced || position.changedMultipleState, 'inactive-position-td': position.status === 2 }">
                  <PositionFixedQtyButton :position="position"/>
                </td>
<!--                <td class="p-p-0" :class="{ 'new-item' : position.isNew, 'deleted-position-td': position.status === 0, 'updated-item': position.isReduced, 'inactive-position-td': position.status === 2 }">-->
<!--                  <a v-if="position.status !== 0" @click.prevent="togglePositionIsActive(position)" href="#" class="p-link table-link-icon">-->
<!--                    <i v-if="position.status === 1" class="pi pi-eye-slash"></i>-->
<!--                    <i v-else-if="position.status === 2" class="pi pi-eye"></i>-->
<!--                  </a>-->
<!--                </td>-->
                <td :class="{
                      'new-item' : position.isNew,
                      'deleted-position-td': position.status === 0,
                      'updated-item': position.isReduced || position.changedMultipleState,
                      'inactive-position-td': position.status === 2
                    }"
                    v-if="!editingIsDisabled && !positionsEditingIsDisabled">
                  <template v-if="position.status !== 0">
                    <ReduceButton v-if="!position.isNew && (position.type === 2 && (position.state >= 4)) && !position.changedMultipleState" @click="reduceRequestPositionOnClick(position)"/>
                    <DeleteButton v-else-if="!position.isNew && ((position.type !== 2 || position.type === 2 && (position.state <= 3)) || (position.type === 2 && (position.state >= 4) && position.changedMultipleState))" @click="confirmPositionDelete(position)"/>
                    <RemoveButton v-else-if="position.isNew " @click="removePosition(index)"/>
                  </template>
<!--                  <template v-if="!editingIsDisabled && !positionsEditingIsDisabled && position.status !== 0">-->
<!--                  </template>-->

<!--                  <RestoreDeletedButton v-show="false" v-else-if="position.status === 0" @click="restoreDeletedOrderPosition(position)"/>-->
                </td>
              </template>
            </tr>

            <tr v-if="useConsumables && consumable">
              <td>
                <i class="ti-spray"></i>
              </td>
              <td></td>
              <td></td>
              <td>
                <InputText :disabled="editingIsDisabled || positionsEditingIsDisabled"
                           :class="{'p-invalid warning-background' : submitted && useConsumables && consumable && !consumable.name}"
                           v-model.trim="consumable.name"
                           class="table-body__name-input--name"
                           autocomplete="off"/>
              </td>
              <td>
                <InputNumber :locale="computedNumberInputLocale" v-model="consumable.qty" class="table-body__qty-input" mode="decimal" disabled :min="2" :max="999" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>
              </td>
              <td>
<!--                <InputNumber -->
<!--                :locale="computedNumberInputLocale"-->
<!--                    :value="consumable.purchase_price" -->
<!--                    class="table-body__purchase-input" -->
<!--                    mode="decimal" disabled -->
<!--                    :min="0" -->
<!--                    :minFractionDigits="2" :maxFractionDigits="3" -->
<!--                    autocomplete="off"/>-->
<!--                <InputText value="-" disabled/>-->
              </td>
              <td>
                <InputNumber v-model="consumable.sell_price"
                             :locale="computedNumberInputLocale"
                             disabled
                             class="table-body__sell-input"
                             mode="decimal"
                             :min="0"
                             :minFractionDigits="2"
                             :maxFractionDigits="4"
                             autocomplete="off"/>
              </td>
              <td v-show="showDiscountColumn">
<!--                <InputNumber :locale="computedNumberInputLocale" @input="changeConsumableDiscount($event.value)" v-model="consumable.discount" class="table-body__discount-input" mode="decimal" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>-->
                <InputNumber :locale="computedNumberInputLocale"
                             :disabled="editingIsDisabled || positionsEditingIsDisabled"
                             @input="updatePosition(consumable, $event.value, 'discount', 100, 0)"
                             v-model="consumable.discount"
                             class="table-body__discount-input"
                             mode="decimal"
                             :min="0"
                             :max="100"
                             :minFractionDigits="2"
                             :maxFractionDigits="2"
                             autocomplete="off"/>
              </td>
              <td v-show="showMarginColumn"></td>
              <td>
                <InputNumber :locale="computedNumberInputLocale" v-model="consumable.sum" class="table-body__sum-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
              </td>
              <td>
                <CustomDropdown @change="changeTax(consumable, $event.value)"
                                    :class="{'p-invalid' : submitted && useConsumables && consumable && !consumable.selectedTax}"
                                    class="table-body__tax-input"
                                    v-model="consumable.selectedTax"
                                    :disabled="editingIsDisabled || positionsEditingIsDisabled"
                                    :options="taxesData"
                                    :filter="false"
                                    optionLabel="value"
                                    :showClear="false">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <span>{{ slotProps.value.value }}%</span>
                    </div>
                    <span v-else style="visibility: hidden">.</span>
                  </template>
                  <template #option="slotProps">
                    <div>
                      <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                    </div>
                  </template>
                </CustomDropdown>
<!--                {{ formatDecimal(consumable.tax_value) }}%-->
              </td>
              <td>
                <InputNumber :locale="computedNumberInputLocale" v-model="consumable.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
              </td>
              <td colspan="5"></td>
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td v-if="isWarrantyOrder"></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
            </tr>

            <tr style="background-color: inherit">
              <td style="padding-right: 10px; line-height: 1.5; text-align: right;" colspan="16">
                <div class="p-text-right p-d-flex p-flex-column p-align-end">
                  <div class="p-d-flex p-ai-center">
                    <div>Sum without tax: </div>
                    <div class="table-summary__price">{{ formatCurrency(summary.sum) }}</div>
                  </div>
                  <div class="p-d-flex p-ai-center">
                    <div>Tax: </div>
                    <div class="table-summary__price">{{ formatCurrency(summary.tax) }}</div>
                  </div>
                  <div class="p-d-flex p-ai-center p-text-bold">
                    <div>Grand total: </div>
                    <div class="table-summary__price">{{ formatCurrency(summary.grand_total) }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
<!--        <div v-else class="datatable&#45;&#45;small-text" style="text-align: center; padding: 35px;">{{ $t('No data available') }}</div>-->
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt"></i>-->
<!--          <Textarea :disabled="editingIsDisabled" id="customer_comment" class="textarea-vertical-resize" v-model.trim="itemData.customer_comment" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="customer_comment">Customer comment</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :disabled="editingIsDisabled"
                              :rows="2"
                              v-model="itemData.customer_comment"
                              :maxChars="customerCommentMaxChars"
                              :id="'order_modal_customer_comment'"
                              :label="'Customer comment'"/>
        <div class="p-mt-2 p-mb-3" v-if="itemData.comments_history?.find(comment => comment.type === 1)" >
          <span>{{ $t('Change history') }}: </span>
          <a @click.prevent="toggleCustomerCommentsHistoryOverlay($event, itemData.id)" href="#" class="p-link table-link-icon">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.comments_history.filter(comment => comment.type === 1).length }}</span>
          </a>
        </div>
        <OverlayPanel v-if="itemData.id" :ref="'mch' + itemData.id">
          <div v-for="(history, index) of (itemData.comments_history).filter(comment => comment.type === 1).sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.comment">{{ history.comment }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== itemData.comments_history.filter(comment => comment.type === 1).length - 1">
          </div>
        </OverlayPanel>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
<!--        <div class="p-float-label p-input-icon-right" >-->
<!--          <i @click="toggleWarning" :class="{'warning-color':itemData.is_warning && itemData.staff_comment}"  class="ti-comment-alt p-link" />-->
<!--          <Textarea :disabled="editingIsDisabled" id="staff_comment" class="textarea-vertical-resize" :class="{'warning-color':itemData.is_warning && itemData.staff_comment}" v-model.trim="itemData.staff_comment" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="staff_comment">Internal comment</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :disabled="editingIsDisabled"
                              :rows="2"
                              v-model="itemData.staff_comment"
                              :maxChars="internalCommentMaxChars"
                              :id="'order_modal_staff_comment'"
                              :label="'Internal comment'"/>
        <div class="p-mt-2 p-mb-3" v-if="itemData.comments_history?.find(comment => comment.type === 2)">
          <span>{{ $t('Change history') }}: </span>
          <a @click.prevent="toggleStaffCommentsHistoryOverlay($event, item.id)" href="#" class="p-link table-link-icon">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.comments_history.filter(comment => comment.type === 2).length }}</span>
          </a>
        </div>
        <OverlayPanel v-if="itemData.id" :ref="'msh' + itemData.id">
          <div v-for="(history, index) of (itemData.comments_history).filter(comment => comment.type === 2).sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.comment">{{ history.comment }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== itemData.comments_history.filter(comment => comment.type === 2).length - 1">
          </div>
        </OverlayPanel>
      </div>

<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">-->
<!--        <div class="p-d-flex p-ai-center">-->
<!--          <div>-->
<!--            <InputSwitch id="add_recommendations_order_modal" v-model="addRecommendations"/>-->
<!--          </div>-->
<!--          <label for="add_recommendations_order_modal" class="switch-label pointer">Add recommendations</label>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div v-show="addRecommendations" class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">-->
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea :disabled="editingIsDisabled" id="recommendation" class="textarea-vertical-resize" v-model.trim="itemData.recommendation" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="recommendation">Recommendations</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :disabled="editingIsDisabled"
                              :rows="2"
                              v-model="itemData.recommendation"
                              :maxChars="recommendationMaxChars"
                              :id="'order_modal_recommendations'"
                              :label="'Recommendations'"/>
        <div class="p-mt-2 p-mb-3" v-if="itemData.recommendation_history?.length" >
          <span>{{ $t('Change history') }}: </span>
          <a @click.prevent="toggleRecommendationHistoryOverlay($event, item.id)" href="#" class="p-link table-link-icon">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ itemData.recommendation_history.length }}</span>
          </a>
        </div>
        <OverlayPanel v-if="itemData.id" :ref="'mrh' + itemData.id">
          <div v-for="(history, index) of (itemData.recommendation_history)?.sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.recommendation">{{ history.recommendation }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== itemData.recommendation_history.length - 1">
          </div>
        </OverlayPanel>
      </div>

      <div v-if="selectedCar?.plate_number" class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <span class="p-mr-2">{{ $t('Car data') }}:</span>
        <a target="_blank" :href="'https://eteenindus.mnt.ee/public/soidukTaustakontroll.jsf?regMark=' + selectedCar.plate_number">
          <i style="font-size: 0.85rem" class="pi pi-external-link"></i>
          {{ selectedCar.plate_number }}
        </a>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>

  <ConfirmDeleteModal
      :modalDataIsLoading="modalDataIsLoading"
      :visible="confirmPositionDeleteModal"
      @close="closeConfirmPositionDeleteModal"
      @delete-item="deletePosition"
      :data="positionToDelete?.name">
  </ConfirmDeleteModal>

  <CustomerModal :visible="customerModal"
                 :modalLayer="2"
                 :item="customer"
                 :taxes="taxesData"
                 @close="closeCustomerModal"
                 @update-item="updateCustomer">
  </CustomerModal>

  <CarModal :visible="carModal"
            :modalLayer="2"
            :userId="selectedCustomer ? +selectedCustomer.id : null"
            :item="car"
            @close="closeCarModal"
            @update-item="updateCar">
  </CarModal>

  <SupplierModal :visible="supplierModal"
                 :modalLayer="2"
                 :item="supplier"
                 :taxes="taxesData"
                 @close="closeSupplierModal"
                 @update-item="updateSupplier">
  </SupplierModal>

  <WarehouseItemModal :visible="warehouseItemModal"
                      :item="warehouseItem"
                      @close="closeWarehouseItemModal"
                      @update-item="updateWarehouseItem">
  </WarehouseItemModal>

  <ReduceRequestPositionModal :visible="reduceRequestPositionModal"
                              :modalDataIsLoading="modalDataIsLoading"
                              :item="positionToReduce"
                              :orderSummary="summary"
                              @reduce-request-position="reduceRequestPosition"
                              @close="closeReduceRequestPositionModal">
  </ReduceRequestPositionModal>

  <ConfirmModal :visible="confirmEditClosedFinancialPeriodDataModal"
                :text="'Financial period already closed. ' + $t('Are you sure you want to proceed?')"
                @close="closeConfirmEditClosedFinancialPeriodDataModal"
                @confirm="editClosedFinancialPeriodDataModal">
  </ConfirmModal>
  <ConfirmModal :visible="confirmSellingPriceIsTooLowModal"
                :text="'Selling price of position is smaller that it\'s purchase price. ' + $t('Are you sure you want to proceed?')"
                @close="closeConfirmSellingPriceIsTooLowModal"
                @confirm="saveItemAnyway">
  </ConfirmModal>
</template>

<script>
import httpClient from '@/services/http.services'
import OrderStates from '@/translations/states/OrderStates'
import CustomerModal from '@/pages/users/components/CustomerModal'
import CarModal from '@/pages/cars/components/CarModal'
import SupplierModal from '@/pages/data_directory/components/SupplierModal'
import PositionHistoryButton from '@/components/PositionHistoryButton'
import formatMixins from '@/mixins/formatMixins'
import generateMixins from '@/mixins/generateMixins'
import permissionsMixins from '@/mixins/permissionsMixins'
import RemoveButton from '@/components/DataTable/RemoveButton'
import ReduceRequestPositionModal from '@/pages/orders/components/ReduceRequestPositionModal'
import ReduceButton from '@/components/DataTable/ReduceButton'
import PositionFixedQtyButton from '@/components/PositionFixedQtyButton'
// import httpMixins from "@/mixins/httpMixins";
import modalWithPositionsMixins from "@/mixins/modalWithPositionsMixins";
// import SmsMessages from "@/translations/SmsMessages";
// import settings from "@/settings";
import WarehouseItemModal from "@/pages/warehouse/components/WarehouseItemModal";
import RequestPositionStateButton from "@/pages/orders/components/RequestPositionStateButton";
// import RestoreDeletedButton from "@/components/DataTable/RestoreDeletedButton";
import MessageBar from "@/components/MessageBar";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import webSocketMixins from "@/mixins/webSocketMixins";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import sortingMixins from "@/mixins/sortingMixins";

export default {
  mixins: [ formatMixins, generateMixins, permissionsMixins, showErrorsMixins, modalWithPositionsMixins, overlayVisibilityMixins, webSocketMixins, sortingMixins ],
  components: { CustomerModal, CarModal, SupplierModal, PositionHistoryButton, RequestPositionStateButton, RemoveButton, ReduceRequestPositionModal, ReduceButton, WarehouseItemModal, PositionFixedQtyButton, MessageBar, CustomerStatusHistoryButton },
  emits: ['close', 'update-items', 'update-item', 'change-updated-item-id', 'change-is-warning', 'update-request-state-history', 'update-customer'],
  name: 'OrderModal',
  props: {
    fromOffersModule: {
      type: Boolean,
      Default: true
    },
  },
  data() {
    return {
      row: null,
      randomHash: null,
      marginTopSpace: 0,
      isWarrantyOrder: false,
      changeStatesToOrderedIsClicked: false,
      createRemindAppointmentNotification: false,
      dataIsSending: false,
      modalDataIsLoading: false,
      isRemindAppointmentDisabled: false,
      remindAppointment: false,
      isRemindAppointmentTogglerOn: false,
      // remindAppointmentWasActive: true,
      sendNotificationAt: null,
      sendNotificationDue: null,
      notificationSmsText: null,
      startDateIsValid: false,
    }
  },
  watch: {
    visible(boolean) {
      if (!boolean) {
        this.startDateIsValid = false
        this.notificationSmsText = null
        this.isRemindAppointmentDisabled = false

        this.remindAppointment = false
        this.isRemindAppointmentTogglerOn = false
        this.sendNotificationAt = null
        this.sendNotificationDue = null
        this.notificationSmsText = null

        this.changeStatesToOrderedIsClicked = false
        this.isWarrantyOrder = false

        this.marginTopSpace = 0

        // if (this.$store.state.webSocket?.readyState === 1 && this.itemData?.id) {
        //   this.$store.state.webSocket.send(JSON.stringify({'action' : 'closeOrder', 'order_id' : this.itemData.id}))
        // }
        this.randomHash = null
      }
    },
    '$store.state.updatedOrderId'(id) {
      if (!id) return false

      if (id === this.itemData?.id) {
        this.itemData.wasUpdatedByThirdParty = true
        this.marginTopSpace = 30

        // const refs = this.$refs[orderModal]


        // const $ref = this.$refs['orderModal']
        // console.log($ref)
        // const orderModalMarginTop = $ref.style.marginTop || 0
        // console.log(orderModalMarginTop, parseInt(orderModalMarginTop))
        // const parsedOrderModalMarginTop = parseInt(orderModalMarginTop)
        // const newMargin = (+parsedOrderModalMarginTop + 100) + 'px'
        // $ref.$el.style.marginTop = newMargin
        // console.log(newMargin)
        // console.log($ref.style)

        // this.editingIsDisabled = true
        // this.customerDisabled = true
        // this.carDisabled = true
        // this.disableSaveButton = true
        // this.isRemindAppointmentDisabled = true
      }
      // this.$emit('change-updated-item-id', id)
      this.$emit('update-item', id)

      setTimeout(() => {
        this.$store.commit('changeUpdatedOrderId', null)
      }, 100)
    },
    item(value) {
      // if (typeof value === 'object' && !Object.keys(value)?.length) {
      //   return
      // }
      if (!value) return false
      // if (this.$store.state.webSocket?.readyState === 1 && value.id) {
      //   this.randomHash = this.createRandomHash(8)
      //   this.$store.state.webSocket.send(JSON.stringify({
      //         'action' : 'openOrder',
      //         'order_id' : value.id,
      //         'hash': this.randomHash,
      //         'order_number' : value.number,
      //         'opened_by': this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name
      //       }))
      // }

      // if (value.id) {
      //   // this.randomHash = this.createRandomHash(8)
      //   const socketData = {
      //     'order_id' : value.id,
      //     'hash': this.randomHash,
      //     'order_number' : value.number,
      //     'opened_by': this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name
      //   }
      //   // console.log(1224124)
      //   this.sendWebSocketData('openOrder', socketData, true)
      //   // this.$store.state.webSocket.send(JSON.stringify({
      //
      //   // }))
      // }

      this.itemData = { ...value }

      this.summary.sum = value.sum
      this.summary.tax = value.tax
      this.summary.grand_total = value.grand_total

      this.customerDisabled = !!(value.customerDisabled || value.state === 12)
      this.carDisabled = !!(value.carDisabled || value.state === 12)

      if (value.name) {
        this.itemData.have_name = true
      }

      if (value.customer_comment) {
        this.itemData.customer_comment = value.customer_comment
      }

      if (value.additional_customer_wishes) {
        this.itemData.customer_comment += ' + ' + value.additional_customer_wishes
      }

      // this.itemData.customerDisable = value.customer.disabled || value.status === 5

      if (value.customer) {
        this.selectedCustomer = { ...value.customer }
        this.customers = [value.customer]
        // this.customers.push(value.customer)

        // this.customers = [{...value.customer}]
        // this.newcustomer = { ...value.customer }

        // this.customers = []
        // this.customers.push(this.selectedCustomer)
        this.changeCustomer()
      }
      if (value.responsible) {
        this.selectedResponsible = {
          id: value.responsible.id,
          full_name: (value.responsible.first_name || '') + ' ' + (value.responsible.last_name || '')
        }
      } else {
        this.selectedResponsible = {
          id: this.$store.state.user.id,
          full_name: (this.$store.state.user.first_name || '') + ' ' + (this.$store.state.user.last_name || '')
        }
      }
      if (value.car) {
        this.selectedCar = { ...value.car }
        // this.cars = [value.car]
        // this.cars.push(value.car)
        // this.originalSelectedCarId = value.car.id
      }

      // if (value.masters && !value.isNew) {
      if (value.masters) {
        const sortedMasters = value.masters.sort((a, b) => a.id - b.id)

        this.selectedMasters = sortedMasters.map(master => {
          const masterInMastersDataArray = this.mastersData.find(m => m.id === master.user?.id)
          const masterData = {id: master.user.id, full_name: (master.user.first_name) + ' ' + (master.user.last_name)}
          if (!masterInMastersDataArray) {
            this.mastersData.push(masterData)
          }
          return masterData
        })
      }

      if (value.start) {
        const startDate = new Date(value.start * 1000)
        this.itemData.start = startDate
        this.itemData.originalStartDate = startDate
      } else {
        this.itemData.start = new Date()
      }

      this.checkStartDateIsValid()
      // this.checkDateIsChanged()

      if (value.is_archived && !this.userIsAdminOrAccountant) {
        this.disableArchivedOrderDataEditing()
        // if () {
        //   this.disableArchivedOrderDataEditing()
          // this.editingIsDisabled = true
          // this.positionsEditingIsDisabled = true
          // this.customerDisabled = true
          // this.carDisabled = true
        // }
        // else {
        //   this.editingIsDisabled = false
        //   this.positionsEditingIsDisabled = false
        //   this.customerDisabled = false
        //   this.carDisabled = false
        // }
      }

      if (this.itemData.orderId) {   //For dublicate child order from parent archieved order
        this.editingIsDisabled = false
        this.positionsEditingIsDisabled = false
        this.customerDisabled = false
        this.carDisabled = false
      }

      if (value.state) {
          this.itemData.selectedState = OrderStates.find(state => state.num === value.state)
      } else {
        this.itemData.selectedState = this.OrderStates[0]
      }

      if (value.recommendation) {
        this.addRecommendations = true
      } else {
        this.addRecommendations = false
      }

      if (!value.orderId && value.sales_invoice_issued_date) {
        if (this.$store.state.branchData?.financial_period_closed_due > value.sales_invoice_issued_date) {
          this.invoiceFinancialPeriodIsClosed = true
          this.disableClosedFinancialPeriodDataEditing()
        } else {
          this.invoiceFinancialPeriodIsClosed = false
        }
      }

      if (value.id) {
        if (!this.settings.useSmsService) {
          this.remindAppointment = false
          this.isRemindAppointmentTogglerOn = false
          this.isRemindAppointmentDisabled = true
          this.createRemindAppointmentNotification = false
        } else {
          this.remindAppointment = !!value.remind_appointment
          this.isRemindAppointmentTogglerOn = !!value.remind_appointment
          this.createRemindAppointmentNotification = !!value.remind_appointment
        }
      } else if (this.settings.useSmsService) {
        this.remindAppointment = true
        this.isRemindAppointmentTogglerOn = true
        this.createRemindAppointmentNotification = true
      }

      this.calculateRemindAppointmentState()
      this.constructConsumable(value)
      //
      if (value.positions?.length) {
        let positionSorting = 1
        this.positions = value.positions.map(position => {
          // if (position.status === 0)  this.deletedPositions = true
          const selectedService = position.type === 1 ? this.findSelectedService(position) : null
          const selectedTax = this.findSelectedTax(position)
          const selectedMaster = position.type === 1 ? this.findSelectedMaster(position) : null
          const selectedSupplier = position.type === 2 ? this.findSelectedSupplier(position) : null
          const selectedWarehouseItem = position.type === 3 ? this.findSelectedWarehouseItem(position) : null
          // const selectedArea = position.type === 1 && position.area ? position.area : null

          // const selectedService = position.type === 1 && position.service ? position.service : null
          // const selectedTax = position.tax ? position.tax : null
          // const selectedMaster = position.type === 1 && position.master ? position.master : null
          // const selectedSupplier = position.type === 2 && position.supplier ? position.supplier : null
          // const selectedWarehouseItem = position.type === 3 && position.warehouseItem ? position.warehouseItem : null

          if (position.discount && +position.discount !== 0) {
            this.showDiscountColumn = true
          }

          let purchasePrice = 0
          if (selectedService && position.isNew) {
            purchasePrice = selectedService.purchase ? +selectedService.purchase : 0
          } else {
            purchasePrice = position.purchase_price ? +position.purchase_price : 0
          }

          if (position.status === 1 && position.is_warranty) {
            this.isWarrantyOrder = true
          }

          return {
            id: position.id,
            order_id: position.order_id,
            isNew: !!position.isNew,
            isUpdated: false,
            sorting: position.sorting ?? !!position.isNew ? positionSorting++ : null,
            type: position.type,
            name: position.name,
            code: position.code ? position.code : null,
            // raw_code: position.code ? this.generateRawCode(position.code) : null,
            raw_code: position.raw_code ? position.raw_code : null,
            qty: position.qty ? +position.qty : 0,
            is_decimal: position.type === 3 && position.warehouseItem?.is_decimal,
            ordered_qty: position.type === 2 && position.ordered_qty && !position.isNew ? +position.ordered_qty : null,
            fixed_qty: position.type === 2 && position.fixed_qty && !position.isNew ? +position.fixed_qty : null,
            reduced_qty: position.reduced_qty ? +position.reduced_qty : 0,
            // purchase_price: position.purchase_price ? +position.purchase_price : 0,
            purchase_price: purchasePrice,
            purchase_sum: position.purchase_sum ? +position.purchase_sum : 0,
            sell_price: position.sell_price ? +position.sell_price : 0,
            sell_price_fixed: !!(position.sell_price && position.type === 2),
            discount: position.discount && +position.discount !== 0 ? +position.discount : null,
            discount_sum: position.discount_sum && +position.discount_sum !== 0 ? +position.discount_sum : 0,
            margin: position.type === 1 ? null : this.calculatePositionMargin(position),
            // sum: (position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * position.qty,
            // total: ((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * position.qty) * (((position.tax?.value || 0) / 100) + 1),
            selectedTax: selectedTax,
            tax_id: position.tax_id,
            tax_value: position.tax_value,
            tax_sum: position.tax_sum ? +position.tax_sum : 0,
            sum: position.sum ? +position.sum : 0,
            total: position.total ? +position.total : 0,
            updated: false,
            state: position.state && !position.isNew ? +position.state : position.type === 2 ? 1 : null,
            status: position.status,
            // service: position.service ? position.service : null,
            service_id: position.service_id,
            selectedService: selectedService,
            // area: position.area,
            warehouse_item_id: position.warehouse_item_id,
            selectedWarehouseItem: selectedWarehouseItem,
            master_id: position.master_id,
            selectedMaster: selectedMaster,
            supplier_id: position.supplier_id,
            selectedSupplier: selectedSupplier,
            request_state_history: position.request_state_history && !position.isNew ? position.request_state_history : null,
            history: position.history && !position.isNew ? position.history : null,
            created_at: position.created_at && !position.isNew ? position.created_at : null,
            creator: position.creator && !position.isNew ? position.creator : null,
            deleted_at: position.deleted_at && !position.isNew ? position.deleted_at : null,
            deletedBy: position.deletedBy && !position.isNew ? position.deletedBy : null,
            originalServiceId: position.service_id,
            originalPurchasePrice: position.purchase_price ? +position.purchase_price : 0,
            originalPurchaseSum: position.purchase_sum ? +position.purchase_sum : 0,
            originalSellPrice: position.sell_price ? +position.sell_price : 0,
            originalQty: position.qty ? +position.qty : 0,
            originalMasterId: position.master_id,
            originalMaster: selectedMaster,
            originalSupplierId: position.supplier_id,
            originalSupplier: selectedSupplier,
            originalWarehouseItemId: position.warehouse_item_id,
            originalWarehouseItem: selectedWarehouseItem,
            is_warranty: !!position.is_warranty,
            originalIsWarranty: !!position.is_warranty,
            // originalPosState: position.type === 2 && position.state ? position.state : null,
            // suppliersData: position.type === 2 ? [] : null
          }
        })
        // }).sort((a, b) => a.sorting - b.sorting)
      }
    },
  },
  methods: {
    resetUpdatedPositionData() {
      this.positions?.forEach(p => {
        p.isNew = false
        p.isUpdated = false
      })
      this.consumable = this.originalConsumable
    },
    updateItem() {
      // this.$emit('update-items', this.item.id)
      // if (!userIsNew) {
      const id = this.itemData?.id
      if (!id) return
      this.$emit('update-item', id)
      this.sendUpdateOrderWebSocket(id)
      // }
    },
    createRandomHash(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    updateRequestStateHistory(positionId, requestStateHistory, newState) {
      if (!positionId || !requestStateHistory) return false
      const position = this.positions?.find(pos => pos.id === positionId)
      if (position) {
        position.changedMultipleState = false
      }
      this.$emit('update-request-state-history', this.item.id, positionId, requestStateHistory, newState)
    },
    toggleWarrantyPosition(position) {
      if (position.isNew || position.originalIsWarranty === position.is_warranty) {
        position.isUpdated = false
      } else {
        position.isUpdated = !this.isNew
      }
    },
    toggleWarrantyOrder() {
      const activePositions = this.positions?.filter(pos => pos.status === 1)
      if (activePositions?.length) {
        activePositions.forEach(p => {
          p.is_warranty = !!this.isWarrantyOrder

          if (p.originalIsWarranty === p.is_warranty) {
            p.isUpdated = false
          } else {
            // p.isUpdated = true
            p.isUpdated = !this.isNew
          }
          // if (!p.discount && p.is_warranty) {
          //   p.discount = 100
          //   this.updatePosition(p)
          // } else if (!p.is_warranty && +p.discount === 100) {
          //   p.discount = null
          //   this.updatePosition(p)
          // }
        })
      }
    },
    changeStatesToOrdered() {
      const computedActiveUnorderedRequests = this.computedActiveUnorderedRequests

      const oldPositions = computedActiveUnorderedRequests.filter(pos => pos.id && pos.type === 2)
      const newPositions = computedActiveUnorderedRequests.filter(pos => !pos.id && pos.type === 2)

      this.changeStatesToOrderedIsClicked = true

      if (oldPositions?.length) {
        oldPositions.forEach(request => {
          request.originalState = request.state
          request.state = 4
          request.isUpdated = true
          request.changedMultipleState = true
        })
      }
      if (newPositions?.length) {
        newPositions.forEach(request => {
          request.originalState = request.state
          request.state = 4
          request.changedMultipleState = true
        })
      }

    },
    undoChangeStatesToOrdered() {
      this.changeStatesToOrderedIsClicked = false
      // const requestsWithChangedState = this.positions.filter(pos => pos.originalState && pos.type === 2)
      const requestsWithChangedState = this.positions.filter(pos => pos.changedMultipleState && pos.type === 2)
      if (requestsWithChangedState?.length) {
        requestsWithChangedState.forEach(request => {
          request.state = request.originalState
          request.isUpdated = false
          request.changedMultipleState = false
        })
      }
    },
    // changeOrderState() {
    //   if (!this.itemData.selectedState) return false
    //
    //   if (this.itemData.selectedState.num === 1) {
    //     console.log(1)
    //     this.remindAppointment = this.isRemindAppointmentTogglerOn
    //   } else {
    //     console.log(2)
    //     this.remindAppointment = false
    //     this.createRemindAppointmentNotification = false
    //   }
    // },
    toggleRemindAppointment() {
      this.isRemindAppointmentTogglerOn = !!this.remindAppointment
      this.createRemindAppointmentNotification = !!this.remindAppointment
    },
    // isTomorrowsDate(date) {
    //   if (!date || !this.isDateValid(date)) return false
    //
    // },
    // checkDateIsChanged() {
    //   if (this.remindAppointmentWasActive && this.itemData.start && this.startDateIsValid) {
    //     const newDate = new Date(this.itemData.start)
    //     const newDateYear = newDate.getFullYear()
    //     const newDateMonth = newDate.getMonth()
    //     const newDateDay = newDate.getDate()
    //
    //     const originalDate = new Date(this.itemData.originalStartDate)
    //     const originalDateYear = originalDate.getFullYear()
    //     const originalDateMonth = originalDate.getMonth()
    //     const originalDateDay = originalDate.getDate()
    //     if (newDateYear === originalDateYear && newDateMonth === originalDateMonth && newDateDay === originalDateDay) {
    //       this.preventCreateNewNotification = true
    //     } else {
    //       this.preventCreateNewNotification = false
    //     }
    //   }
    // },
    checkStartDateIsValid() {
      if (this.itemData.start) {
        this.startDateIsValid = this.isDateValid(this.itemData.start)
      }
    },
    isDateValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    // changeStartDateOnInput() {
    //   if (!this.itemData.start) return false
    //   this.checkStartDateIsValid()
    // },
    // changeStartDateOnBlur() {
    //   if (!this.itemData.start) return false
    //   this.checkStartDateIsValid()
    // },
    changeStartDate() {
      if (!this.itemData.start) return false
      this.checkStartDateIsValid()
      this.calculateRemindAppointmentState()
      // this.checkDateIsChanged()
    },
    // calculateRemindAppointmentState() {
    //   const orderStartDate = this.itemData.start
    //   const originalStartDate = this.itemData.originalStartDate
    //
    //   if (!orderStartDate || !this.startDateIsValid) {
    //     return false
    //   }
    //
    //   const now = new Date()
    //   const nowYear = now.getFullYear()
    //   const nowMonth = now.getMonth()
    //   const nowDay = now.getDate()
    //   // const nowHours = now.getHours()
    //   // const nowMinutes = now.getMinutes()
    //
    //   // const orderStartDate = startDate
    //   const orderStartYear = orderStartDate.getFullYear()
    //   const orderStartMonth = orderStartDate.getMonth()
    //   const orderStartDay = orderStartDate.getDate()
    //   // const orderStartHours = orderStartDate.getHours()
    //   // const orderStartMinutes = orderStartDate.getMinutes()
    //
    //   if (orderStartYear < nowYear ||
    //       orderStartYear === nowYear && orderStartMonth < nowMonth ||
    //       orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay <= nowDay ||
    //       orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay === nowDay + 1) {
    //       // orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay === nowDay && orderStartHours <= nowHours ||
    //       // orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay === nowDay && orderStartHours === nowHours && orderStartMinutes <= nowMinutes) {
    //
    //     this.remindAppointment = false
    //     this.isRemindAppointmentDisabled = true
    //     this.sendNotificationAt = null
    //   } else if (this.settings.useSmsService) {
    //     this.sendNotificationAt = orderStartDay === nowDay + 1 ? new Date() : new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 10, 30)
    //
    //     this.sendNotificationDue = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 19, 30)
        // this.sendNotificationDue = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 23, 59)
    //
    //     if (+this.sendNotificationDue < +now) {
    //       this.remindAppointment = false
    //       this.isRemindAppointmentDisabled = true
    //       return false
    //     }
    //
    //     if (originalStartDate) {
    //       const originalStartYear = originalStartDate.getFullYear()
    //       const originalStartMonth = originalStartDate.getMonth()
    //       const originalStartDay = originalStartDate.getDate()
    //
    //       if (orderStartDay === nowDay + 1) {
    //
    //         if (originalStartYear === orderStartYear && originalStartMonth === orderStartMonth && originalStartDay === orderStartDay) {
    //           if (this.itemData.remind_appointment) {
    //             this.remindAppointment = true
    //             this.isRemindAppointmentDisabled = true
    //             // this.sendNotificationAt = null // Commented at 08.12.2022
    //           } else {
    //             this.remindAppointment = true
    //             this.isRemindAppointmentDisabled = false
    //             // this.sendNotificationAt = null
    //           }
    //         } else {
    //           this.remindAppointment = true
    //           this.isRemindAppointmentDisabled = false
    //           // this.sendNotificationAt = orderStartDay === nowDay + 1 ? new Date() : new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 10, 30)
    //         }
    //       } else {
    //         this.remindAppointment = true
    //         this.isRemindAppointmentDisabled = false
    //         // this.sendNotificationAt = orderStartDay === nowDay + 1 ? new Date() : new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 10, 30)
    //       }
    //
    //     } else {
    //       // this.remindAppointment = true
    //       this.isRemindAppointmentDisabled = false
    //       // this.sendNotificationAt = orderStartDay === nowDay + 1 ? new Date() : new Date(orderStartYear, orderStartMonth, orderStartDay - 1, 10, 30)
    //     }
    //   }
    // },
    calculateRemindAppointmentState() {
      const orderStartDate = this.itemData.start
      const originalStartDate = this.itemData.originalStartDate
      const originalRemindAppointment = this.itemData.remind_appointment

      const orderStateIsNew = this.itemData.selectedState?.num === 1

      if (!orderStartDate || !this.startDateIsValid) {
        return false
      }

      const now = new Date()
      const nowYear = now.getFullYear()
      const nowMonth = now.getMonth()
      const nowDay = now.getDate()
      // const nowHours = now.getHours()
      // const nowMinutes = now.getMinutes()

      // const orderStartDate = startDate
      const orderStartYear = orderStartDate.getFullYear()
      const orderStartMonth = orderStartDate.getMonth()
      const orderStartDay = orderStartDate.getDate()
      // let orderStartHours = orderStartDate.getHours()
      // let orderStartMinutes = orderStartDate.getMinutes()

      const isOrderStartTodayOrBeforeToday = !!(orderStartYear < nowYear ||
          orderStartYear === nowYear && orderStartMonth < nowMonth ||
          orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay <= nowDay)

      const isOrderStartTomorrow = !!(orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay === nowDay + 1)

      if (originalStartDate) {
        // console.log(1)
        const originalStartYear = originalStartDate.getFullYear()
        const originalStartMonth = originalStartDate.getMonth()
        const originalStartDay = originalStartDate.getDate()

        if (isOrderStartTodayOrBeforeToday) {
          // console.log(11)
          this.remindAppointment = false
          // this.remindAppointment = !!originalRemindAppointment
          this.isRemindAppointmentDisabled = true
          this.createRemindAppointmentNotification = false
        } else if (isOrderStartTomorrow) {
          // console.log(12)
          if (originalStartYear === orderStartYear &&
              originalStartMonth === orderStartMonth &&
              originalStartDay === orderStartDay) {
            // console.log(121)
            if (originalRemindAppointment) {
              // console.log(1211)
              this.isRemindAppointmentDisabled = true
              // this.remindAppointment = !!originalRemindAppointment
              this.remindAppointment = true
              this.createRemindAppointmentNotification = false
              // this.isRemindAppointmentTogglerOn = true
            } else {
              // console.log(1212)
              this.isRemindAppointmentDisabled = false
              this.remindAppointment = !!this.isRemindAppointmentTogglerOn
              this.createRemindAppointmentNotification = !!this.isRemindAppointmentTogglerOn
            }
          } else {
            // console.log(122)
            if (orderStateIsNew) {
              // console.log(1221)
              this.isRemindAppointmentDisabled = false
              this.remindAppointment = !!this.isRemindAppointmentTogglerOn
              this.createRemindAppointmentNotification = !!this.isRemindAppointmentTogglerOn
            } else {
              // console.log(1222)
              this.isRemindAppointmentDisabled = false
              this.remindAppointment = false
              this.createRemindAppointmentNotification = false
            }
          }
        } else {
          // console.log(13)
          if (orderStateIsNew) {
            // console.log(131)
            this.isRemindAppointmentDisabled = false
            this.remindAppointment = !!this.isRemindAppointmentTogglerOn
            this.createRemindAppointmentNotification = !!this.isRemindAppointmentTogglerOn
          } else {
            // console.log(132)
            this.isRemindAppointmentDisabled = false
            this.remindAppointment = false
            this.createRemindAppointmentNotification = false
          }
        }
      } else {
        // console.log(2)
        if (isOrderStartTodayOrBeforeToday) {
          // console.log(21)
          this.remindAppointment = false
          this.isRemindAppointmentDisabled = true
          this.createRemindAppointmentNotification = false
        } else {
          // console.log(22)
          if (orderStateIsNew) {
            // console.log(221)
            this.isRemindAppointmentDisabled = false
            this.remindAppointment = !!this.isRemindAppointmentTogglerOn
            this.createRemindAppointmentNotification = !!this.isRemindAppointmentTogglerOn
          } else {
            // console.log(222)
            this.isRemindAppointmentDisabled = false
            this.remindAppointment = false
            this.createRemindAppointmentNotification = false
          }
          // console.log(22)
          // this.isRemindAppointmentDisabled = false
          // this.remindAppointment = !!this.isRemindAppointmentTogglerOn
          // this.createRemindAppointmentNotification = !!this.isRemindAppointmentTogglerOn
        }
      }


      if (this.itemData.is_archived) {
        this.isRemindAppointmentDisabled = true
        this.createRemindAppointmentNotification = false
      }
      // if (orderStartHours < settings.minTimeToSendSms.hours) {
      //   orderStartHours = settings.minTimeToSendSms.hours
      //   orderStartMinutes = 0
      // } else if (orderStartHours > settings.maxTimeToSendSms.hours) {
      //   orderStartHours = settings.maxTimeToSendSms.hours
      //   orderStartMinutes = 0
      // }
      // this.sendNotificationAt = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, orderStartHours, orderStartMinutes)
      // this.sendNotificationDue = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, settings.maxTimeToSendSms.hours, settings.maxTimeToSendSms.minutes)
    },
    // disableRemindAppointmentToggler() {
    //   this.remindAppointment = false
    //   this.isRemindAppointmentDisabled = true
    //   this.createRemindAppointmentNotification = false
    // },
    // enableRemindAppointmentToggler() {
    //   this.remindAppointment = false
    //   this.isRemindAppointmentDisabled = true
    //   this.createRemindAppointmentNotification = false
    // },
    reduceRequestPositionOnClick(positionToReduce) {
      this.positionToReduce = positionToReduce
      this.reduceRequestPositionModal = true
      if (!this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', true)
      }
    },
    closeReduceRequestPositionModal() {
      this.positionToReduce = {}
      this.reduceRequestPositionModal = false
      if (this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', false)
      }
    },
    async reduceRequestPosition(modalData) {
      if (!modalData) return false
      this.modalDataIsLoading = true

      let qtyWasChanged = false

      if (modalData.changeQtyInOrder) {
        qtyWasChanged = true
        this.positionToReduce.qty -= modalData.qtyToReturn
        this.updatePosition(this.positionToReduce) //Don't delete!
      }

      const obj = {
        order_id: this.itemData.id,
        order_position_id: this.positionToReduce.id,
        supplier_id: this.positionToReduce.supplier_id,
        code: this.positionToReduce.code,
        raw_code: this.positionToReduce.code ? this.generateRawCode(this.positionToReduce.code) : null,
        name: this.positionToReduce.name,
        qty_to_return: modalData.qtyToReturn,
        purchase_price: this.positionToReduce.purchase_price,
        purchase_sum: this.positionToReduce.purchase_sum,
        sell_price: this.positionToReduce.sell_price,
        discount: this.positionToReduce.discount,
        discount_sum: this.positionToReduce.discount_sum,
        tax_id: this.positionToReduce.selectedTax?.id,
        tax_sum: this.positionToReduce.tax_sum,
        // qty: this.positionToReduce.qty,
        type: modalData.typeId,
        reason: modalData.reasonId,
        comment: modalData.comment,
        position_sum: this.positionToReduce.sum,
        position_total: this.positionToReduce.total,
        change_qty_in_order: modalData.changeQtyInOrder
        // sum: this.summary.sum ?? null,
        // tax: this.summary.tax ?? null,
        // grand_total: this.summary.grand_total ?? null,
        // taxesArray: this.generateTaxesArray(),
      }

      try {
        const { status, data } = await httpClient.post(`order-position/reduce-request-position`, obj)
        if (status === 200 && data?.success) {
          // const orderState = data.state
          // this.itemData.state = orderState
          this.positionToReduce.reduced_qty += modalData.qtyToReturn;

          // this.updatePosition(this.positionToReduce)
          // const state = OrderStates.find(state => state.num === orderState)
          // if (state) {
          //   this.itemData.selectedState = state
          // }

          this.positionToReduce.isReduced = true

          const historyData = {
            returned: modalData.qtyToReturn,
            // type: modalData.typeId,
            reason: modalData.reasonId,

          }

          if (qtyWasChanged) {
            historyData.qty = [modalData.oldQty, modalData.oldQty - modalData.qtyToReturn]
          }

          this.positionToReduce.history?.push({
            created_at: +new Date() / 1000,
            creator: { first_name: this.$store.state.user.first_name, last_name: this.$store.state.user.last_name },
            data: JSON.stringify(historyData)
          })

          if (+this.positionToReduce.qty === 0) {
            this.positionToReduce.status = 0
            this.positionToReduce.isNew = false
            this.positionToReduce.isUpdated = false
            // if (!this.deletedPositions) {
            //   this.deletedPositions = true
            // }
            this.positionToReduce.deleted_at = +new Date() / 1000
            this.positionToReduce.deletedBy = { first_name: this.$store.state.user.first_name, last_name: this.$store.state.user.last_name }
          }

          this.closeReduceRequestPositionModal()

          this.$emit('update-item', this.itemData.id)
          this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});

          // if (this.$store.state.webSocket?.readyState === 1) {
          //   this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOrder', 'order_id' : this.itemData.id}))
          // }
          this.sendUpdateOrderWebSocket(this.itemData.id)
        } else if (data?.error) {
          if (qtyWasChanged) {
            this.positionToReduce.qty += modalData.qtyToReturn
            this.updatePosition(this.positionToReduce) //Don't delete!
          }
          if (data.error.reduced_qty_limit_err) {
            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Position reduced qty limit', life: this.settings.toastLife});
          } else {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        }
      } catch(err) {
        if (qtyWasChanged) {
          this.positionToReduce.qty += modalData.qtyToReturn
          this.updatePosition(this.positionToReduce) //Don't delete!
        }
        this.showError(err)
      } finally {
        // this.updatePosition(this.positionToReduce)
        this.modalDataIsLoading = false
      }
    },
    async deletePosition(showToast = true) {
      this.modalDataIsLoading = true
      this.positionToDelete.status = 0

      if (this.positionToDelete.type === 1) {
        this.updateConsumable()
      }
      this.updateSummary()

      const obj = {
        positionId: this.positionToDelete.id,
        consumable: this.consumable,
        orderId: this.itemData.id,
        // grand_total: this.summary.grand_total && this.positionToDelete.sum ? (this.summary.grand_total).toFixed(2) : null,
        // sum: this.summary.sum ?? null,
        // tax: this.summary.tax ?? null,
        // grand_total: this.summary.grand_total ?? null,
        // delete_payment: this.positionToDelete.type === 1 && this.itemData.have_employee_payments,
        // taxesArray: this.generateTaxesArray(),
        // time: this.computedServicesTime,
        branch_timezone: this.$store.state.branchData?.timezone,
      }

      try {
        const { status, data } = await httpClient.post('order-position/delete', obj)
        if (status === 200 && data?.success) {
          const orderState = data.state
          // this.positionToDelete.status = 0
          this.positionToDelete.isNew = false
          this.positionToDelete.isUpdated = false
          // this.positionToDelete.deleted_at = +new Date() / 1000
          this.positionToDelete.deleted_at = Date.now() / 1000
          this.positionToDelete.deletedBy = {
            first_name: this.$store.state.user.first_name,
            last_name: this.$store.state.user.last_name,
          }

          // if (this.positionToDelete.type === 1) {
          //   this.updateConsumable()
          // }
          // this.updateSummary()

          this.itemData.state = orderState
          const state = OrderStates.find(state => state.num === orderState)
          if (state) {
            this.itemData.selectedState = state
          }
          this.closeConfirmPositionDeleteModal()
          if (showToast) {
            this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife})
          }
          // this.deletedPositions = true
          // this.$emit('update-items', this.itemData.id)
          this.$emit('update-item', this.itemData.id)

          // if (this.$store.state.webSocket?.readyState === 1) {
          //   this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOrder', 'order_id' : this.itemData.id}))
          // }
          this.sendUpdateOrderWebSocket(this.itemData.id)
        } else if (data?.already_deleted) {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Position already deleted', life: this.settings.toastLife});
        } else if (data?.error?.summary === 'Already ordered') {
          this.positionToDelete.status = 1
          this.$toast.add({severity:'warn', detail: 'Request already ordered', life: this.settings.toastLife});
          if (data.request_state_history) {
            this.positionToDelete.request_state_history = data.request_state_history
            this.positionToDelete.state = data.request_state_history[data.request_state_history.length - 1]?.new
          }
          this.closeConfirmPositionDeleteModal()
        } else if (data?.error) {
          this.positionToDelete.status = 1
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        } else {
          this.positionToDelete.status = 1
        }
      } catch(err) {
        // this.showError(err)
        this.positionToDelete.status = 1
        this.showError(err)
      } finally {
        if (this.positionToDelete.type === 1) {
          this.updateConsumable()
        }
        this.updateSummary()
        this.modalDataIsLoading = false
      }
    },
    async toggleWarning() {
      const itemId = this.itemData?.id
      if (!this.itemData.staff_comment || !itemId) return false
      this.itemData.is_warning = !this.itemData.is_warning
      try {
        const { status } = await httpClient.post('order/toggle-warning', { id: itemId, state: this.itemData.is_warning })
        if (status === 200) {
          this.$emit('change-is-warning', itemId, this.itemData.is_warning)
        } else {
          this.itemData.is_warning = !this.itemData.is_warning
        }
      } catch(err) {
        this.itemData.is_warning = !this.itemData.is_warning
        this.showError(err)
      }
    },
    async saveItem() {
      // console.log('createRemindAppointmentNotification: ', this.createRemindAppointmentNotification)
      // console.log('remindAppointment: ', this.remindAppointment)
      // this.disableSaveButton = true
      // this.dataIsSending = true
      this.submitted = true

      if (!this.startDateIsValid ||
          !this.itemData.selectedState ||
          (this.isAnyActiveServicePosition && !this.selectedCustomer) ||
          // !this.selectedResponsible ||
          // !this.itemData.name ||
          !this.itemData.start ||
          (this.useConsumables && this.consumable && (!this.consumable.name || !this.consumable.selectedTax)) ||
          (this.itemData.customer_comment && this.itemData.customer_comment.length > this.customerCommentMaxChars) ||
          (this.itemData.staff_comment && this.itemData.staff_comment.length > this.internalCommentMaxChars) ||
          (this.itemData.recommendation && this.itemData.recommendation.length > this.recommendationMaxChars)
      ) {
        // this.disableSaveButton = false
        // this.dataIsSending = false
        return false
      }

      let wrongInputs = false

      this.positions?.filter(position => position.status === 1)?.forEach(position => {
        // if (this.positions?.length && (!position.name || !position.sell_price || !position.qty  || !position.selectedTax)) {
        if (!position.name || !position.sell_price || !position.qty  || !position.selectedTax) {
          wrongInputs = true
        }
        if (position.type === 1 && !position.selectedService) {
          wrongInputs = true
        }
        if (position.type === 2 && !position.selectedSupplier) {
          wrongInputs = true
        }
        if (position.type === 3 && !position.selectedWarehouseItem) {
          wrongInputs = true
        }
        if (position.sell_price < position.purchase_price && !this.ignoreSellingPriceIsTooLow) {
          this.openConfirmSellingPriceIsTooLowModal()
          wrongInputs = true
        }
      })

      // if (this.useConsumables && this.consumable && (!this.consumable.name || !this.consumable.selectedTax)) {
      //   wrongInputs = true
      // }

      if (wrongInputs) {
        // this.disableSaveButton = false
        // this.dataIsSending = false
        return false
      }

      if ((this.selectedIssuedDateFinancialPeriodIsClosed || this.invoiceFinancialPeriodIsClosed) && !this.editClosedFinancialPeriodDataConfirmed) {
        // if (this.userIsAdminOrAccountant) {
        this.openConfirmEditClosedFinancialPeriodDataModal()
        // }
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const order = {
        id: this.itemData.id ?? null,
        // start: this.itemData.start ? (Date.parse(this.itemData.start))/1000 : null,
        start: this.itemData.start ? +new Date(this.itemData.start / 1000) : +new Date(Date.now() / 1000),
        // end: this.itemData.end ? (Date.parse(this.itemData.end))/1000 : null,
        end: this.itemData.end ? +new Date(this.itemData.end / 1000)  : null,
        user_id: this.selectedCustomer ? this.selectedCustomer.id : null,
        car_id: this.selectedCar ? this.selectedCar.id : null,
        car_user_id: this.selectedCar ? this.selectedCar.user_id : null,
        state: this.itemData.selectedState ? this.itemData.selectedState.num : null,
        name: this.itemData.name ? this.itemData.name.trim() : null,
        area_id: this.selectedArea ? this.selectedArea.id : null,
        mileage: this.itemData.mileage ? this.itemData.mileage : null,
        use_consumables: this.useConsumables,
        // recommendation: this.addRecommendations && this.itemData.recommendation ? this.itemData.recommendation : null,
        recommendation: this.itemData.recommendation ? this.itemData.recommendation.trim() : null,
        responsible_id: this.selectedResponsible?.id,
        branch_id: this.$store.state.branchData ? this.$store.state.branchData.id : null,
        customer_comment: this.itemData.customer_comment ? this.itemData.customer_comment.trim() : null,
        staff_comment: this.itemData.staff_comment ? this.itemData.staff_comment.trim() : null,
        sum: this.summary.sum ?? null,
        tax: this.summary.tax ?? null,
        grand_total: this.summary.grand_total ?? null,
        offerId: this.itemData.offerId ?? null,
        orderId: this.itemData.orderId ?? null,
        paymentsToDeleteIds: this.paymentsToDeleteIds?.length ? this.paymentsToDeleteIds : null,
        // taxesArray: this.generateTaxesArray(),
        time: this.computedServicesTime,
        branch_timezone: this.$store.state.branchData?.timezone,
        generate_task_order: !!this.generateTaskOrder && this.positions.filter(pos => pos.status === 1 && pos.type === 1),
        newPositions: this.generateNewPositionsData(),
        updatedPositions: this.generateUpdatedPositionsData(),
        remind_appointment: this.remindAppointment,
        create_notification: this.createRemindAppointmentNotification,
        editClosedFinancialPeriodDataConfirmed: this.editClosedFinancialPeriodDataConfirmed,
        // isDevMode: false,
        // removeAllMasters: false,
        // newPositions: null,
        // updatedPositions: null,
        // carIsChanged: false,
        // status: null,
        // is_hidden: false,
        // consumable: null,
      }

      if (this.remindAppointment && settings.useSmsService) {
        this.generateSendNotificationAtTime(this.itemData.start)
        this.generateSmsNotificationText(this.itemData.start)
        if (this.notificationSmsText && this.sendNotificationAt && this.sendNotificationDue) {
          order.send_notification_at = +new Date(this.sendNotificationAt / 1000)
          order.send_notification_due = +new Date(this.sendNotificationDue / 1000)
          order.notification_sms_text = this.notificationSmsText
        }
      }

      if (this.useConsumables && this.consumable) {
        order.consumable = {
          id: this.itemData.id ?? null,
          name: this.consumable.name,
          qty: this.consumable.qty,
          // purchase_price: this.consumable.purchase_price,
          sell_price: this.consumable.sell_price,
          discount: this.consumable.discount,
          // discount_type: this.consumable.discount_type,
          discount_sum: this.consumable.discount_sum,
          tax_id: this.consumable.selectedTax.id,
          tax_value: this.consumable.tax_value,
          tax_sum: this.consumable.tax_sum,
          sum: this.consumable.sum,
          total: this.consumable.total
        }
      }

      if (this.updateMasters || !this.itemData.id) {
        order.masters = this.selectedMasters?.map(master => master.id) || null
      } else {
        order.masters = null
      }

      if (this.itemData.id) {
        if (this.updateMasters && !this.selectedMasters?.length) {
          order.removeAllMasters = true
        }

        try {
          const { status, data } = await httpClient.post(`order/update`, order)
          if (status === 200 && data?.success) {
            // this.$emit('update-items', this.itemData.id)
            // this.$emit('update-item', this.itemData, this.summary, this.selectedCustomer, this.selectedCar)
            this.$emit('update-item', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()
            this.sendUpdateOrderWebSocket(this.itemData.id)
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else if (data?.financial_period_is_closed) {
            this.$store.commit('updatedBranchDataFinancialPeriodDueDate', data.financial_period)
            this.invoiceFinancialPeriodIsClosed = true

            if (!this.userIsAdminOrAccountant) {
              this.disableClosedFinancialPeriodDataEditing()
              this.resetUpdatedPositionData()
              // this.positionsWillNotBeChanged = true
            }

            this.openConfirmEditClosedFinancialPeriodDataModal()
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
          this.editClosedFinancialPeriodDataConfirmed = false
          this.ignoreSellingPriceIsTooLow = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`order/create`, order)
          if (status === 201 && data?.success) {
            this.itemData.id = data.id
            order.id = data.id
            this.itemData.isNew = true
            this.$emit('update-items', (this.itemData.offerId ?? this.itemData.id))
            if (this.fromOffersModule) {
              this.sendUpdateOfferWebSocket(this.itemData.offerId)
              this.$emit('update-item', this.itemData.offerId)
            } else {
              this.$emit('update-item', this.itemData.id)
            }
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife})
            this.close()

            if (this.$store.state.webSocket?.readyState === 1) {
              this.$store.state.webSocket.send(JSON.stringify({'action' : 'createOrder'}))
            }
            // this.itemData.id = data
            // order.id = data
            // this.itemData.isNew = true
            // this.$emit('update-items', (this.itemData.offerId ?? this.itemData.id))
            // this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife})
            // this.close()
          } else if (data?.error) {
            if (this.fromOffersModule && data.error.type === 'Order already exists') {
              this.$emit('update-item', this.itemData.offerId)
              this.close()
            }
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.ignoreSellingPriceIsTooLow = false
        }
      }
    },
  },
  computed: {
    // computedPositionsOrder() {
    //   return this.positions?.sort((a, b) => a - b)
    // },
    userIsAdminOrAccountant() {
      const superAdmin = this.constants.userRoles.superAdmin
      const admin = this.constants.userRoles.admin
      const accountant = this.constants.userRoles.accountant

      return !!(this.$store.state.user.role === superAdmin || this.$store.state.user.role === admin || this.$store.state.user.role === accountant)
    },
    computedActiveUnorderedRequests() {
      if (!this.positions) return []
      const activeUnorderedRequests = this.positions.filter(pos => pos.type === 2 && pos.status === 1 && pos.state <= 3)
      return activeUnorderedRequests
    },
    thereIsPositionWithChangedMultipleState() {
      return !!this.positions.find(pos => pos.type === 2 && pos.status === 1 && pos.changedMultipleState)
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: this.marginTopSpace + 10 + 'px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '85%', marginTop: this.marginTopSpace + 15 + 'px'}
      } else {
        return {position: 'absolute', top: 0, width: '90%', maxWidth: '1600px', marginTop: this.marginTopSpace + 'px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.p-button-text {
  width: auto;
}

.radio-button-label {
  padding: 6px;
  margin-left: 0;
  cursor: pointer;
}

.dropdown-wrapper {
  display: flex;
}

.dropdown__select {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown__select--border-radius {
  width: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__select--one-button-width {
  width: calc(100% - 2.357rem);
}

.dropdown__select--two-buttons-width {
  width: calc(100% - (2.357rem + 2.357rem));
}

.dropdown__buttons {
  display: flex;
}

.dropdown__edit-button {
  width: 2.357rem;
  border-radius: 0;
}

.dropdown__edit-button--rounded {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__create-button{
  width: 2.357rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table-wrapper {
  overflow-y: auto;
}

//.datatable > td {
//  padding: 0 !important;
//}

.positions-table {
  width: 100%;
  border-collapse: collapse;
  .table-body__name-input--first {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--code {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--name {
    min-width: 130px;
    width: 15vw;
  }
  .table-body__qty-input {
    min-width: 75px;
    max-width: 120px;
  }
  .table-body__purchase-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__sell-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__discount-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__margin-input {
    min-width: 50px;
    max-width: 110px;
  }
  .table-body__sum-input {
    min-width: 65px;
    max-width: 125px;
  }
  .table-body__tax-input {
    min-width: 60px;
    width: 5vw;
  }
  .table-body__total-input {
    min-width: 65px;
    max-width: 125px;
  }

  .table-summary__price {
    width: 90px;
  }
}

//td {
//  //padding: 6px !important;
//  background-color: red;
//}

.position-icon {
  padding: 4px 3px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  i {
    font-size: 1.1em;
  }
}

//TESTING
//body{
//  color:white;
//}
//td,tr,th{
//  border:1px solid white;
//  border-collapse: collapse;
//  cursor:all-scroll;
//}
//table{
//  border-collapse: collapse;
//  -webkit-user-select: none; /* Safari */
//  -ms-user-select: none; /* IE 10+ and Edge */
//  user-select: none; /* Standard syntax */
//}
//.drop-zone {
//  background-color: #eee;
//  margin-bottom: 10px;
//  padding: 10px;
//}
//
//.drag-el {
//  background-color: #fff;
//  margin-bottom: 10px;
//  padding: 5px;
//}
</style>