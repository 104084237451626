<template>
  <div class="warning-block primary-text"
       :class="{
        'red-background' : severity === 'error',
        'yellow-background' : severity === 'warn',
        'warning-block--fixed' : fixedToTop,
        'warning-block--modal' : !fixedToTop,
       }">
    <i style="font-size: 1.2rem;" class="pi pi-exclamation-triangle p-mr-2"></i>
    <div>{{ text }}</div>
<!--    <div style="position: absolute; top: 15px; right: 5px">-->
<!--      <i class="pi pi-times"></i>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'MessageBar',
  props: {
    text: String,
    // error: {
    //   type: Boolean,
    //   default: true
    // },
    // warn: {
    //   type: Boolean,
    //   default: false
    // },
    severity: String,
    fixedToTop: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style lang="scss" scoped>
.warning-block {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 12px 8px;
  color: white;
  //width: 100vw;
  //z-index: 999999;
  font-size: 1.1rem;
}

.warning-block--fixed {
  position: fixed;
  top: 0;
  left: 0;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  padding: 12px 8px;
  //color: white;
  width: 100vw;
  z-index: 999999;
  //font-size: 1.1rem;
}

.warning-block--modal {
  padding: 10px;
  margin-bottom: 16px;
  margin-top: -10px;
  border-radius: 3px;
}

.red-background {
  //background-color: rgb(234, 29, 29);
  background-color: #e60017; //from error toast style left border
  //color: scale-color(#e60017, $lightness: 95%);
  //background-color: #FFCDD2;
  //color: scale-color(#FFCDD2, $lightness: -75%);
}

.yellow-background {
  background-color: #FFECB3;
  color: scale-color(#FFECB3, $lightness: -75%);
}
</style>