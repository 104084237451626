<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
<!--      <div v-if="!item?.id" class="p-dialog-title">Employee payments</div>-->
<!--      <div v-else class="p-dialog-title">Employee payments</div>-->
      <div class="p-dialog-title">Reduce request position</div>
    </template>
    <Spinner v-if="modalDataIsLoading"/>
    <div v-else-if="positionData" class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced-position-supplier" disabled :modelValue="positionData.selectedSupplier?.company_name || '-'" autocomplete="off"/>
          <label for="reduced-position-supplier">Supplier</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced-position-code" disabled :modelValue="positionData.code || '-'" autocomplete="off"/>
          <label for="reduced-position-code">Code</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="reduced-position-name"
                     style="min-width: 100px"
                     :class="{'p-invalid' : submitted && !positionData.name}"
                     v-model="positionData.name"
                     autocomplete="off"/>
          <label for="reduced-position-name">Name<span class="warning-color">*</span></label>
        </div>
      </div>


      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="reduced-position-ordered-qty" disabled :modelValue="maxQtyToReturn" autocomplete="off"/>
          <label for="reduced-position-ordered-qty">Max qty for return</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber id="reduced-position-qty-to-return"
                       :locale="computedNumberInputLocale"
                       :inputClass="{'p-invalid' : submitted && (!qtyToReturn || (qtyToReturn > maxQtyToReturn))}"
                       v-model="qtyToReturn"
                       :disabled="!positionData?.ordered_qty || positionData?.ordered_qty === 0"
                       :maxFractionDigits="2"
                       :min="0"
                       :max="maxQtyToReturn"
                       showButtons
                       autocomplete="off"/>
          <label for="reduced-position-qty-to-return">Return qty<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && ((qtyToReturn > maxQtyToReturn) || (qtyToReturn === 0 && maxQtyToReturn === 0)) ">Position reduced qty limit</small>
        <small class="p-invalid" v-else-if="submitted && !qtyToReturn">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="reduced-position-type-dropdown"
                          :disabled="!positionData?.qty"
                          :class="{'p-invalid' : submitted && !selectedType}"
                          v-model="selectedType"
                          :options="ReducedPositionTypes">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>
                  <span>{{ slotProps.value.label[$i18n.locale] }}</span>
                </div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="reduced-position-type-dropdown">Type<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedType">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="reduced-position-reason-dropdown"
                          :disabled="!positionData?.qty"
                          :class="{'p-invalid' : submitted && !selectedReason}"
                          v-model="selectedReason"
                          :options="ReducedPositionReasons">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>
                  <span>{{ slotProps.value.label[$i18n.locale] }}</span>
                </div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="reduced-position-reason-dropdown">Reason<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedReason">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea id="supplier_comment" v-model="comment" rows="3" :autoResize="true" autocomplete="new-password"/>-->
<!--          <label for="supplier_comment">{{ $t('Comment') }}</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :rows="2"
                              :required="selectedReason && selectedReason.id === 9"
                              v-model="comment"
                              :maxChars="commentMaxChars"
                              :id="'reduced-position-comment'"
                              :label="$t('Comment')"/>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12" v-if="userIsAdminOrAccountant">
        <div class="p-d-flex p-ai-center">
          <Checkbox v-model="changeQtyInOrder" :binary="true" id="delete-from-order-checkbox"/>
          <label for="delete-from-order-checkbox" class="p-ml-1 pointer">Change qty in order</label>
        </div>
      </div>
    </div>
    <div v-else class="no-data-block">
      {{ $t('No data available') }}
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" :disabled="modalDataIsLoading" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="!positionData?.qty || modalDataIsLoading" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
// import httpClient from '@/services/http.services'
import settings from '@/settings'
// import Dropdown from '@/components/Dropdown'
import ReducedPositionReasons from '@/translations/ReducedPositionReasons'
import ReducedPositionTypes from "@/translations/ReducedPositionTypes";
import generateMixins from '@/mixins/generateMixins'
import modalWithPositionsMixins from "@/mixins/modalWithPositionsMixins";
import Spinner from "@/components/Spinner";
import formatMixins from "@/mixins/formatMixins";
import permissionsMixins from "@/mixins/permissionsMixins";

export default {
  mixins: [ generateMixins, modalWithPositionsMixins, formatMixins, permissionsMixins ],
  components: { Spinner },
  emits: ['close', 'reduce-request-position'],
  name: 'ReduceRequestPositionModal',
  props: {
    item: Object,
    visible: Boolean,
    orderSummary: Object,
    modalDataIsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      commentMaxChars: 250,
      changeQtyInOrder: true,
      positionData: {},
      submitted: false,
      selectedType: null,
      selectedReason: null,
      qtyToReturn: 1,
      comment: null,
      ReducedPositionReasons,
      ReducedPositionTypes,
      settings,
      maxQtyToReturn: 0,
    }
  },
  watch: {
    item(value) {
      this.positionData = { ...value }
      if ((value.ordered_qty || +value.ordered_qty === 0)) {
        this.maxQtyToReturn = +value.ordered_qty - (value.reduced_qty ?? 0)
        this.qtyToReturn = this.maxQtyToReturn ? 1 : 0
      }
    },
    visible() {
      if (!this.visible) {
        this.selectedType = null
        this.selectedReason = null
        this.submitted = false
        this.qtyToReturn = 0
        this.comment = null
        this.changeQtyInOrder = true
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.selectedType ||
          !this.selectedReason ||
          !this.qtyToReturn ||
          !this.positionData.name ||
          (this.comment && this.comment.length > this.commentMaxChars) ||
          (this.selectedReason?.id === 9 && !this.comment?.length) ||
          (this.qtyToReturn > this.maxQtyToReturn)) {
        return false
      }

      const modalData = {
        oldQty: this.item.qty,
        qtyToReturn: this.qtyToReturn,
        typeId: this.selectedType?.id,
        reasonId: this.selectedReason?.id,
        comment: this.comment,
        changeQtyInOrder: this.changeQtyInOrder
      }

      this.$emit('reduce-request-position', modalData, this.qtyToReturn)
      // this.close()


      // const taxValue = +this.positionData.selectedTax?.value || 0
      //
      // const positionSum = ((this.positionData.sell_price || 0) - (((this.positionData.discount || 0) / 100) * (this.positionData.sell_price || 0))) * this.qtyToReturn
      // // const positionTotal = (((this.positionData.sell_price || 0) - (((this.positionData.discount || 0) / 100) * (this.positionData.sell_price || 0))) * this.qtyToReturn) * (((this.positionData.selectedTax ? this.positionData.selectedTax.value : 0) / 100) + 1)
      //
      // const summarySum = this.orderSummary.sum - positionSum
      // const summaryGrandTotal = summarySum * ((taxValue / 100) + 1)
      // const summaryTax = summaryGrandTotal - summarySum
      //
      // const obj = {
      //   order_id: this.positionData.order_id,
      //   order_position_id: this.positionData.id,
      //   supplier_id: this.positionData.supplier_id,
      //   code: this.positionData.code,
      //   raw_code: this.positionData.code ? this.generateRawCode(this.positionData.code) : null,
      //   name: this.positionData.name,
      //   qty: this.qtyToReturn,
      //   purchase_price: this.positionData.purchase_price,
      //   sell_price: this.positionData.sell_price,
      //   discount: this.positionData.discount,
      //   // tax_id: this.positionData.tax_id,
      //   reason: this.selectedReason?.id,
      //   comment: this.comment,
      //   sum: summarySum,
      //   tax: summaryTax,
      //   grand_total: summaryGrandTotal,
      //   taxesArray: this.generateTaxesArray(),
      // }
      //
      // console.log(obj)
      //
      // try {
      //   const { status, data } = await httClient.post(`order-position/reduce-request-position`, obj)
      //   if (status === 200) {
      //     const orderState = data
      //     this.$emit('reduce-request-position', obj, this.qtyToReturn, orderState)
      //     this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
      //     this.close()
      //   }
      // } catch (err) {
      //   if (err) {
      //     this.$toast.add({severity:'error', summary: this.$t('Error'), detail: `${err.message}`, life: 1750})
      //     console.log(`${err.response?.data?.name}: ${err.response?.data?.message}`)
      //   }
      // }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '90%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '82%', marginTop: '25px'}
    //   } else {
    //     return {position: 'relative', top: '-10vh', width: '87%', maxWidth: '1100px', marginTop: '50px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '95%', maxWidth: '460px', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '460px', marginTop: '25px'}
      } else {
        return {position: 'absolute', top: 0, maxWidth: '460px', marginTop: '50px'}
      }
    }
  },

}
</script>

<style scoped lang="scss">
//.table-wrapper {
//  width: 100%;
//  overflow-y: auto;
//  .positions-table {
//    width: 100%;
//    border-collapse: collapse;
//  }
//}

.no-data-block {
  //width: 100%;
  //margin: 20px auto;
  padding: 30px 0;
  text-align: center;
  //background-color: red;
}

</style>