<template>
  <div v-if="responsible" class="avatar-wrapper">
  <!--                      <router-link :to="{ path: `/employees/${responsible.id}` }" target="_blank">-->
  <!--                      <img v-if="responsible.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + responsible.profile_img + '.jpg'" :alt="responsible.first_name + ' ' + responsible.last_name" :title="responsible.first_name + ' ' + responsible.last_name">-->
    <img v-if="responsible.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + responsible.profile_img" :alt="responsible.first_name + ' ' + responsible.last_name" :title="responsible.first_name + ' ' + responsible.last_name">
    <img v-else :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'" :alt="responsible.first_name + ' ' + responsible.last_name" :title="responsible.first_name + ' ' + responsible.last_name">
  <!--                      </router-link>-->
  </div>
  <div v-else-if="masters?.length" class="avatar-wrapper">
    <div v-for="(master, i) of masters" :key="i">
      <img v-if="master.user?.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + master.user.profile_img" :class="{'p-mr-1': masters.length > 1 && i !== masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">
      <img v-else :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'" :class="{'p-mr-1': masters.length > 1 && i !== masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">
    </div>
  </div>
  <i v-else class="ti-minus"></i>
</template>

<script>
import settings from "@/settings";
export default {
  name: "AvatarsCell",
  props: {
    masters: {
      type: Array,
      default: function() {
        return []
      }
    },
    responsible: {
      type: Object
    }
  },
  data() {
    return {
      settings
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //max-height: 45px;
  //max-width: 45px;
  img {
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>