export default [
    {
        num: 1,
        label: {
            en: 'New',
            et: 'Uus',
            ru: 'Новый'
        },
        color: 'green'
    },
    {
        num: 3,
        label: {
            en: 'Paid part.',
            et: 'Os. tasutud',
            ru: 'Опл. част.'
        },
        color: 'blue'
    },
    {
        num: 5,
        label: {
            en: 'Paid',
            et: 'Tasutud',
            ru: 'Оплачен'
        },
        color: 'dark-blue'
    },
    // {
    //     num: 6,
    //     label: {
    //         en: 'New',
    //         et: 'Uus',
    //         ru: 'Новый'
    //     },
    //     color: 'green'
    // },
    // {
    //     num: 7,
    //     label: {
    //         en: 'New',
    //         et: 'Uus',
    //         ru: 'Новый'
    //     },
    //     color: 'green'
    // },
    {
        num: 9,
        label: {
            en: 'Canceled',
            et: 'Tühistatud',
            ru: 'Отменен'
        },
        color: 'red'
    },
]

// export default {
//     en: [
//         {num: 1, label: 'New', color: 'green'},
//         {num: 3, label: 'Paid part.', color: 'blue'},
//         {num: 5, label: 'Paid', color: 'dark-blue'},
//         // {num: 6, label: 'Overpaid', color: 'red'},
//         // {num: 7, label: 'Overdue', color: 'red'},
//         {num: 9, label: 'Canceled', color: 'red'},
//     ],
//     et: [
//         {num: 1, label: 'Uus', color: 'green'},
//         {num: 3, label: 'Os. tasutud', color: 'blue'},
//         {num: 5, label: 'Tasutud', color: 'dark-blue'},
//         // {num: 6, label: 'Overpaid', color: 'red'},
//         // {num: 7, label: 'Overdue', color: 'red'},
//         {num: 9, label: 'Tühistatud', color: 'red'},
//     ],
//     ru: [
//         {num: 1, label: 'Новый', color: 'green'},
//         {num: 3, label: 'Опл. част.', color: 'blue'},
//         {num: 5, label: 'Оплачен', color: 'dark-blue'},
//         // {num: 6, label: 'Overpaid', color: 'red'},
//         // {num: 7, label: 'Overdue', color: 'red'},
//         {num: 9, label: 'Отменен', color: 'red'},
//     ]
// }