'use strict'
const sortingMixins = {
    data() {
        return {
            dragStartDisabled: true,
        }
    },
    methods: {
        rowOnMouseDown(e) {
            if (e.target.classList.contains('grabbable')) {
                this.dragStartDisabled = false
            } else {
                this.dragStartDisabled = true
            }
        },
        handleDragStart(e) {
            // if (this.dragStartDisabled) e.preventDefault()
            e.dataTransfer.setData('text/plain', e.currentTarget.dataset.index)
        },
        handleDragEnter(e) {
            e.currentTarget.classList.add('hover')
        },
        handleDragLeave(e) {
            e.currentTarget.classList.remove('hover')
        },
        handleDragOver(e) {
            // console.log(e)
            e.preventDefault()
        },
        handleDrop(e) {
            e.preventDefault()
            const itemIndex = e.dataTransfer.getData('text/plain'),
                droppedIndex = e.currentTarget.dataset.index
            Array.from(e.currentTarget.parentNode.children).map(tr => {
                tr.classList.remove('hover')
            })
            this.handleListChange(itemIndex, droppedIndex)
        },
        handleListChange(index, newIndex) {
            let oldIndex = parseInt(index, 10),
                newPositionIndex = parseInt(newIndex, 10),
                movedItem = this.positions?.[oldIndex]
            this.positions?.splice(oldIndex, 1)
            this.positions?.splice(newPositionIndex, 0, movedItem)
            this.setNewSorting()
        },
        setNewSorting() {
            let sorting = 1
            this.positions.forEach(p => {
                p.sorting = sorting++
                p.isUpdated = !p.isNew
            })
        },
    }
}

export default sortingMixins
