export default [
    {
        num: 1,
        label: {
            en: 'Piece',
            et: 'Tk',
            ru: 'Штука',
        }
    },
    {
        num: 2,
        label: {
            en: 'Meter',
            et: 'Meeter',
            ru: 'Метр',
        }
    },
    {
        num: 3,
        label: {
            en: 'Liter',
            et: 'Liiter',
            ru: 'Литр',
        }
    },
    {
        num: 4,
        label: {
            en: 'Gram',
            et: 'Gramm',
            ru: 'Грамм',
        }
    }
]
