'use strict'

import OrderStates from "@/translations/states/OrderStates";
import httpClient from "@/services/http.services";
import settings from "@/settings";
import RequestPositionStates from "@/translations/states/RequestPositionStates";
import SalesInvoiceStates from "@/translations/states/SalesInvoiceStates";
import OfferStates from "@/translations/states/OfferStates";
import constants from "@/constants";

const modalWithPositionsMixins = {
    props: {
        item: Object,
        visible: Boolean,
        masters: Array,
        responsibles: Array,
        taxes: Array,
        services: Array,
        suppliers: Array,
        areas: Array,
        warehouseItems: Array,
        isSecondModal: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            ignoreSellingPriceIsTooLow: false,
            confirmSellingPriceIsTooLowModal: false,
            showDiscountColumn: false,
            customerCommentMaxChars: 2000,
            internalCommentMaxChars: 1000,
            recommendationMaxChars: 1000,
            offerAdditionalInformationMaxChars: 1000,
            salesInvoiceCommentMaxChars: 500,
            salesInvoiceStaffCommentMaxChars: 500,
            salesInvoiceRecommendationMaxChars: 1000,
            warehouseCategories: null,
            clearCustomerSearchData: false,
            clearCarSearchData: false,
            showCustomerMinCharsMessage: true,
            showCarMinCharsMessage: true,
            // minimalSearchCharacter: 3,
            customersDataIsLoading: false,
            carsDataIsLoading: false,
            generateTaskOrder: true,
            reduceRequestPositionModal: false,
            positionToReduce: null,
            // lastSortingNum: 1,
            disableSaveButton: false,
            suppliersData: [],
            mastersData: [],
            servicesData: [],
            taxesData: [],
            warehouseItemsData: [],
            selectedState: null,
            positionToUpdate: null,
            paymentsToDeleteIds: [],
            itemData: {},
            // carData: {},
            // customerData: {},
            selectedResponsible: null,
            selectedArea: null,
            submitted: false,
            customerModal: false,
            customer: null,
            customers: null,
            selectedCustomer: null,
            customerSearchData: null,
            carModal: false,
            car: null,
            cars: null,
            customerCars: null,
            selectedCar: null,
            // originalSelectedCarId: null,
            carSearchData: null,
            supplierModal: false,
            supplier: null,
            warehouseItemModal: false,
            warehouseItem: null,
            userCanAddNewWarehouseItem: true,
            confirmPositionDeleteModal: false,
            positionToDelete: {},
            selectedMasters: [],
            updateMasters: false,
            useConsumables: false,
            // deletedPositions: false,
            showCarData: true,
            showDeleted: false,
            consumable: null,
            originalConsumable: null,
            addRecommendations: false,
            positions: [],
            defaultTaxId: null,
            summary: {
                sum: null,
                tax: null,
                grand_total: null
            },
            // payments: [],
            settings,
            constants,
            RequestPositionStates,
            // SalesInvoiceStates: SalesInvoiceStates[this.$i18n.locale],
            SalesInvoiceStates,
            OfferStates: OfferStates.filter(state => state.num !== 5),
            OrderStates: OrderStates.filter(state => state.num === 1 || state.num === 3 || state.num === 5 || state.num === 7 || state.num === 8),
            invoiceFinancialPeriodIsClosed: false,
            selectedIssuedDateFinancialPeriodIsClosed: false,
            editClosedFinancialPeriodDataConfirmed: false,
            confirmEditClosedFinancialPeriodDataModal: false,
            customerDisabled: false,
            carDisabled: false,
            editingIsDisabled: false,
            positionsEditingIsDisabled: false,
            issuedDateIsDisabled: false,
            paymentDueDateIsDisabled: false,
            penaltyInputIsDisabled: false,
            orderStateDropdownIsDisabled: false,
            orderStartDateIsDisabled: false,
            // useConsumablesTogglerIsDisabled: false,
            responsibleDropdownIsDisabled: false,
            selectedMastersDropdownIsDisabled: false,
        }
    },
    watch: {
        suppliers(value) {
            // this.suppliersData = [...value]
            this.suppliersData = value
        },
        masters(value) {
            // this.mastersData = [...value]
            this.mastersData = value
        },
        services(value) {
            // this.servicesData = [...value]
            this.servicesData = value
        },
        taxes(value) {
            // this.taxesData = [...value]
            this.taxesData = value
        },
        warehouseItems(value) {
            // this.warehouseItemsData = [...value]
            this.warehouseItemsData = value
        },
        visible() {
            if (!this.visible) {
                this.itemData = {}
                this.submitted = false
                this.selectedArea = null
                this.selectedCustomer = null
                this.selectedCar = null
                this.selectedResponsible = null
                this.selectedMasters = []
                this.positions = []
                this.updateMasters = false
                this.showDeleted = false
                this.deletedPositions = false
                this.paymentsToDeleteIds = []
                // this.lastSortingNum = 1
                // this.consumable = null

                this.customerSearchData = null
                this.customers = null
                this.customer = null
                this.carSearchData = null
                this.cars = null
                this.car = null
                this.customerCars = null
                this.consumable = null

                this.selectedState = null
                this.payments = []
                this.itemData.orderWasUpdatedByThirdParty = false

                //Makes textareas with normal sizes
                this.itemData.staff_comment = null
                this.itemData.customer_comment = null
                this.itemData.additional_information = null
                this.itemData.recommendation = null
                this.itemData.invoice_comment = null

                this.generateTaskOrder = true

                this.invoiceFinancialPeriodIsClosed = false
                this.selectedIssuedDateFinancialPeriodIsClosed = false
                this.editClosedFinancialPeriodDataConfirmed = false

                this.customerDisabled = false
                this.carDisabled = false
                this.editingIsDisabled = false
                this.positionsEditingIsDisabled = false
                this.disableSaveButton = false
                this.issuedDateIsDisabled = false
                this.paymentDueDateIsDisabled = false
                this.penaltyInputIsDisabled = false
                this.orderStateDropdownIsDisabled = false
                this.orderStartDateIsDisabled = false
                this.responsibleDropdownIsDisabled = false
                this.selectedMastersDropdownIsDisabled = false

                this.showDiscountColumn = false
            }
        },
        '$store.state.secondLayerIsOpened'() {
            if (!this.$store.state.secondLayerIsOpened) {
                if (this.confirmPositionDeleteModal) {
                    this.closeConfirmPositionDeleteModal()
                }
                if (this.customerModal) {
                    this.closeCustomerModal()
                }
                if (this.carModal) {
                    this.closeCarModal()
                }
                if (this.supplierModal) {
                    this.closeSupplierModal()
                }
                if (this.warehouseItemModal) {
                    this.closeWarehouseItemModal()
                }
                if (this.reduceRequestPositionModal) {
                    this.closeReduceRequestPositionModal()
                }
            }
        },
        '$store.state.thirdLayerIsOpened'() {
            if (!this.$store.state.thirdLayerIsOpened) {
                if (this.confirmEditClosedFinancialPeriodDataModal) {
                    this.closeConfirmEditClosedFinancialPeriodDataModal()
                }
                if (this.confirmSellingPriceIsTooLowModal) {
                    this.closeConfirmSellingPriceIsTooLowModal()
                }
            }
        }
    },
    methods: {
        saveItemAnyway() {
            this.ignoreSellingPriceIsTooLow = true
            this.closeConfirmSellingPriceIsTooLowModal()
            this.saveItem()
        },
        calculatePositionMargin(position) {
            const margin = ((position.sum * 100) / position.purchase_sum) - 100
            if (+margin && isFinite(+margin)) {
                return this.formatDecimal(margin, false)
            } else {
                return 0
            }
        },
        disableClosedFinancialPeriodDataEditing() {
            if (this.userIsAdminOrAccountant) return false

            this.customerDisabled = true
            this.carDisabled = true
            // this.editingIsDisabled = true
            this.positionsEditingIsDisabled = true
            this.issuedDateIsDisabled = true
            this.paymentDueDateIsDisabled = true
            this.penaltyInputIsDisabled = true
            this.exportInvoiceCodeInputIsDisabled = true
                // this.disableSaveButton = true

            this.orderStateDropdownIsDisabled = true
            this.orderStartDateIsDisabled = true
            this.responsibleDropdownIsDisabled = true
            this.selectedMastersDropdownIsDisabled = true
        },
        enableClosedFinancialPeriodDataEditing() {
            this.customerDisabled = false
            this.carDisabled = false
            // this.editingIsDisabled = false
            this.positionsEditingIsDisabled = false
            this.issuedDateIsDisabled = false
            this.paymentDueDateIsDisabled = false
            this.penaltyInputIsDisabled = false
            this.exportInvoiceCodeInputIsDisabled = false
            // this.disableSaveButton = false
            this.orderStateDropdownIsDisabled = false
            this.orderStartDateIsDisabled = false
            this.responsibleDropdownIsDisabled = false
            this.selectedMastersDropdownIsDisabled = false
        },
        disableArchivedOrderDataEditing() {
            if (this.userIsAdminOrAccountant) return false

            this.customerDisabled = true
            this.carDisabled = true
            this.editingIsDisabled = true
            this.positionsEditingIsDisabled = true
            this.issuedDateIsDisabled = true
            this.paymentDueDateIsDisabled = true
            this.penaltyInputIsDisabled = true
            this.exportInvoiceCodeInputIsDisabled = true
            // this.disableSaveButton = true

            this.orderStateDropdownIsDisabled = true
            this.selectedMastersDropdownIsDisabled = false
        },
        openConfirmEditClosedFinancialPeriodDataModal() {
            this.confirmEditClosedFinancialPeriodDataModal = true
            // this.$store.commit('openRequiredAppLayer') //don't work... why ?
            this.$store.commit('toggleThirdLayer', true)
        },
        closeConfirmEditClosedFinancialPeriodDataModal() {
            this.confirmEditClosedFinancialPeriodDataModal = false
            // this.$store.commit('closeRequiredAppLayer') //don't work... why ?
            this.$store.commit('toggleThirdLayer', false)

        },
        openConfirmSellingPriceIsTooLowModal() {
            this.confirmSellingPriceIsTooLowModal = true
            // this.$store.commit('openRequiredAppLayer') //don't work... why ?
            this.$store.commit('toggleThirdLayer', true)
        },
        closeConfirmSellingPriceIsTooLowModal() {
            this.confirmSellingPriceIsTooLowModal = false
            // this.$store.commit('closeRequiredAppLayer') //don't work... why ?
            this.$store.commit('toggleThirdLayer', false)

        },
        editClosedFinancialPeriodDataModal() {
            this.closeConfirmEditClosedFinancialPeriodDataModal()
            this.editClosedFinancialPeriodDataConfirmed = true
            this.saveItem()
        },
        restoreDeletedOrderPosition(position) {
            // console.log('restoreDeletedOrderPosition', position)
            position.status = 1
            position.isUpdated = true
        },
        restoreDeletedOfferPosition(position) {
            // console.log('restoreDeletedOfferPosition', position)
            position.status = 1
            position.isUpdated = true
        },
        generateNewPositionsData() {
            const positions = this.positions?.filter(position => position.isNew)
            if (!positions?.length) return null

            const newPosition = this.generatePositionsData(positions)
            return newPosition
        },
        generateUpdatedPositionsData() {
            const positions = this.positions?.filter(position => position.isUpdated)
            if (!positions?.length) return null

            const updatedPosition = this.generatePositionsData(positions)
            return updatedPosition
        },
        generatePositionsData(positions) {
            const positionData = positions.map(position => {
                return {
                    id: position.id,
                    type: position.type,
                    master_id: position.master_id,
                    supplier_id: position.supplier_id,
                    service_id: position.service_id,
                    warehouse_item_id: position.warehouse_item_id,
                    accounting_code: position.accounting_code,
                    code: position.code ? position.code.trim() : null,
                    raw_code: position.raw_code,
                    name: position.name ? position.name.trim() : null,
                    qty: position.qty,
                    purchase_price: position.purchase_price,
                    purchase_sum: position.purchase_sum,
                    sell_price: position.sell_price,
                    discount: position.discount,
                    discount_sum: position.discount_sum,
                    tax_id: position.tax_id,
                    tax_value: position.tax_value,
                    tax_sum: position.tax_sum,
                    sum: position.sum,
                    total: position.total,
                    status: position.status,
                    state: position.state,
                    sorting: position.sorting,
                    supplierChanged: position.supplierChanged,
                    employeeChanged: position.employeeChanged,
                    is_warranty: position.is_warranty,
                }
            })
            return positionData
        },
        togglePositionIsActive(position) {
            if (position) {
                this.$toast.add({severity:'error', summary: `Error`, detail: `Temporary disabled`, life: settings.toastLife})
                return false
            }
            position.status = position.status === 1 ? 2 : 1;
            position.isUpdated = !position.isNew;

            this.checkEmployeePaymentToDelete(position)

            if (position.type === 1) {
                this.updateConsumable()
            }
            this.updateSummary()
        },
        // generateTaxesArray() {
        //     const actualPositions = this.positions.filter(item => item.status)
        //     if (actualPositions?.length) {
        //         const taxes = []
        //         actualPositions.forEach(p => {
        //             if (p.selectedTax?.value && +p.selectedTax.value !== 0) {
        //                 const positionTaxSum = p.total - p.sum
        //                 const tax = {
        //                     id: p.selectedTax.id ?? null,
        //                     value: p.selectedTax.value ? +p.selectedTax.value : null,
        //                     sum: this.formatDecimal(positionTaxSum, false)
        //                 }
        //                 const filteredTax = taxes.find(t => t.value === tax.value)
        //                 if (!filteredTax) {
        //                     taxes.push(tax)
        //                 } else {
        //                     filteredTax.sum += tax.sum
        //                 }
        //             }
        //         })
        //         if (this.consumable?.selectedTax && +this.consumable?.selectedTax.value !== 0) {
        //             const consumableTaxSum = this.consumable.total - this.consumable.sum
        //             const consumable = {
        //                 id: this.consumable.selectedTax.id ?? null,
        //                 value: this.consumable.selectedTax.value ? +this.consumable.selectedTax.value : null,
        //                 sum: this.formatDecimal(consumableTaxSum, false)
        //             }
        //             const filteredTax = taxes.find(t => t.value === consumable.value)
        //             if (!filteredTax) {
        //                 taxes.push(consumable)
        //             } else {
        //                 filteredTax.sum += consumable.sum
        //             }
        //         }
        //         if (taxes.length === 1) {
        //             taxes[0].sum = +this.summary.tax
        //         }
        //         return taxes
        //     } else {
        //         return null
        //     }
        // },
        constructConsumable(value) {
            // if (value.use_consumables && +value.consumable.sum !== 0) {
            if (value.use_consumables) {
                this.useConsumables = true
                if (value.consumable) {
                    if (value.consumable.discount && +value.consumable.discount !== 0) {
                        this.showDiscountColumn = true
                    }
                    this.consumable = {
                        ...value.consumable,
                        qty: +value.consumable.qty,
                        // purchase_price: +value.consumable.purchase_price,
                        // purchase_sum: +value.consumable.purchase_sum,
                        sell_price: this.formatDecimal(value.consumable.sell_price, false),
                        discount: value.consumable.discount && +value.consumable.discount !== 0 ? +value.consumable.discount : null,
                        discount_sum: +value.consumable.discount_sum,
                        selectedTax: this.findSelectedTax(value.consumable),
                        tax_value: value.consumable.tax_value,
                        tax_sum: +value.consumable.tax_sum,
                        sum: +value.consumable.sum,
                        total: +value.consumable.total,
                    }
                    this.originalConsumable = { ...this.consumable }
                    // this.calculateConsumableSummary()
                } else {
                    this.consumable = null
                }
            } else {
                if (!value.id && !value.offerId && !value.orderId) {
                    this.useConsumables = true
                } else {
                    this.useConsumables = false
                }
                this.consumable = null
            }
        },
        // updateItems(id, userIsNew) {
        updateItems(id = null) {
            // this.$emit('update-items', this.item.id)
            // if (!userIsNew) {
                this.$emit('update-items', id)
            // }
        },
        findSelectedWarehouseItem(position) {
            if (!position.warehouseItem?.id) return false
            const warehouseItem = this.warehouseItemsData.find(item => +item.id === +position.warehouseItem.id)
            if (warehouseItem) {
                return warehouseItem
            } else {
                if (position.status) {
                    this.warehouseItemsData.push(position.warehouseItem)
                }
                return position.warehouseItem
            }
        },
        findSelectedService(position) {
            if (!position.service?.id) return false
            const service = this.servicesData.find(service => +service.id === +position.service.id)
            if (service) {
                return service
            } else {
                if (position.status) {
                    this.servicesData.push(position.service)
                }
                return position.service
            }
        },
        findSelectedSupplier(position) {
            if (!position.supplier?.id) return false
            // const supplierData = position.supplier
            // return supplierData

            const supplier = this.suppliersData.find(supplier => +supplier.id === +position.supplier.id)
            if (supplier) {
                return supplier
            } else {
                if (position.status) {
                    this.suppliersData.push(position.supplier)
                }
                return position.supplier
            }
        },
        // findSelectedMaster(position) {
        //     if (!position.master?.id) {
        //         return false
        //     }
        //     const master = this.mastersData.find(master => +master.id === +position.master.id)
        //     if (master) {
        //         const masterIsSelected = !!this.selectedMasters.find(m => m.id === master.id)
        //         if (!masterIsSelected && position.status) {
        //             this.selectedMasters.push(master)
        //             this.updateMasters = true
        //         }
        //         return master
        //     } else {
        //         this.mastersData.push(position.master)
        //         return position.master
        //     }
        // },
        findSelectedMaster(position) {
            if (!position.master?.id) {
                return false
            }
            const masterData = {id: position.master.id, full_name: (position.master.first_name) + ' ' + (position.master.last_name)}
            return masterData
        },
        findSelectedTax(position) {
            if (!position.tax?.id) return false
            // return position.tax
            const tax = this.taxesData.find(tax => +tax.id === +position.tax.id)
            if (tax) {
                return tax
            } else {
                this.taxesData.push(position.tax)
                return position.tax
            }
        },
        toggleRecommendationHistoryOverlay(event, id) {
            if (!event || !id) {
                return false
            }
            this.$refs['mrh' + id.toString()].toggle(event);
        },
        toggleCustomerCommentsHistoryOverlay(event, id) {
            if (!event || !id) {
                return false
            }
            this.$refs['mch' + id.toString()].toggle(event);
        },
        toggleStaffCommentsHistoryOverlay(event, id) {
            if (!event || !id) {
                return false
            }
            this.$refs['msh' + id.toString()].toggle(event);
        },
        createCustomer() {
            this.customerModal = true
            this.customer = {}
            if (!this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', true)
            }
            if (this.customerSearchData && isFinite(this.customerSearchData)) {
                this.customer = {phone_number: this.customerSearchData}
            }
        },
        editCustomer() {
            this.customer = this.selectedCustomer
            this.customerModal = true
            if (!this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', true)
            }
        },
        updateCustomer(user) {
            if (!user) return false
            // console.log(user)
            this.selectedCustomer = { ...user }
            // if (this.customerData.id === user.id) {

            // }
            // if (updateItems) {
            //     this.$emit('update-items')
            // }
            const customerInList = !!this.customers?.find(c => c.id === user.id)
            if (user.isNew || !customerInList) {
                if (!this.selectedCar) {
                    this.cars = []
                    this.selectedCar = null
                }
                if (!this.customers?.length) {
                    this.customers = [{...user}]
                } else {
                    this.customers.push({...user})
                }
            } else {
                this.updateItem()
                // this.updateCustomer()
                // this.$emit('update-customer', user, this.itemData?.id)
                // this.$emit('update-item', this.itemData?.id, user)
            }
            this.changeCustomer()
        },
        // updateCustomer(userId, user, newUser = false) {
        //     if (!user) return false
        //     this.selectedCustomer = user
        //     this.customerData = user
        //     if (this.customerData.id === user.id) {
        //         this.$emit('update-items')
        //     }
        //
        //     if (newUser) {
        //         if (!this.selectedCar) {
        //             this.cars = []
        //             this.selectedCar = null
        //         }
        //     }
        //
        //     this.changeCustomer()
        // },
        changeCustomer() {
            if (this.selectedCustomer) {
                // this.customerSearchData = null
                // this.clearCustomerSearchData = true

                this.carSearchData = null
                this.clearCarSearchData = true
                // this.customers = []
                // this.customers.push(this.selectedCustomer)
                // this.customers = [{...this.selectedCustomer}]
                this.getCustomerCars()
            }

            // if ($event && ($event.value !== this.newcustomer)) {
            //   this.selectedCar = this.carSearchData = this.cars = this.car = null
            // }

            // this.newcustomer = this.selectedCustomer
        },
        async searchCustomers(event) {
        // async searchCustomers(searchData) {
            const value = event.value

            this.customerSearchData = value
            if (value?.length <= 2) {
                this.showCustomerMinCharsMessage = true
                // this.customers = []
                if (value.length === 0 && this.selectedCustomer) {
                    this.customers = [{...this.selectedCustomer}]
                } else {
                    this.customers = null
                }
                return false
            }

            // this.customerSearchData = value
            this.clearCustomerSearchData = false
            // if (this.customerSearchData?.length) {
            this.customersDataIsLoading = true
            try {
                const { status, data } = await httpClient('user/search-customers?expand=address,customerStatus&search=' + this.customerSearchData)
                if (status === 200 && data) {
                    this.customers = data
                }
            } catch(err) {
                this.showError(err)
            } finally {
                this.customersDataIsLoading = false
                this.showCustomerMinCharsMessage = false
            }
            // } else {
            //     this.customers = []
            // }
        },
        closeCustomerModal() {
            this.customerModal = false
            this.customer = {}
            if (this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', false)
            }
        },
        createCar() {
            this.carModal = true
            this.car = {}
            if (!this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', true)
            }
            if (this.carSearchData) {
                this.car.plate_number = this.carSearchData
            }
        },
        editCar() {
            this.car = this.selectedCar
            this.carModal = true
            if (!this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', true)
            }
        },
        updateCar(car) {
            if (!car) return false
            this.selectedCar = {...car}
            // this.car = { ...car }

            // if (!car.isNew && this.cars) {
            //     let carInArray = this.cars.find(item => item.id === car.id)
            //     if (carInArray) {
            //         this.cars[this.cars.indexOf(carInArray)] = {...car}
            //     }
            // }
            // else {
                // this.selectedCar = car
            //     this.cars = []
            //     this.cars.push(car)
            // }
            const carInList = !!this.cars?.find(i => i.id === car.id)
            if (car.isNew || !carInList) {
                // this.cars.push(car)
                if (!this.cars?.length) {
                    this.cars = [{...car}]
                } else {
                    this.cars.push({...car})
                }
            } else {
                this.updateItem()
            }
            this.changeCar()
        },
        async getCustomerCars() {
            if (this.selectedCustomer) {
                this.carsDataIsLoading = true
                try {
                    const { status, data } = await httpClient.post('car/get-customer-cars', { id: this.selectedCustomer.id })
                    if (status === 200 && data) {
                        this.customerCars = data
                        this.cars = data
                    }
                } catch(err) {
                    this.showError(err)
                } finally {
                    this.carsDataIsLoading = false
                }
            }
        },
        async changeCar() {
            if (!this.selectedCar) {
                this.carSearchData = null
                return false
            }
            if (this.selectedCustomer) return false

            try {
                const { status, data } = await httpClient.post('user/get-user-by-id?expand=customerStatus', { id: this.selectedCar.user_id })
                if (status === 200 && data) {
                    // this.carSearchData = null
                    this.customerSearchData = null
                    this.clearCustomerSearchData = true
                    // this.selectedCustomer = this.customerData = data
                    this.selectedCustomer = data
                    this.customers = [{...data}]
                    // this.customers = []
                    // this.customers.push(data)
                    this.changeCustomer()
                }
            } catch(err) {
                this.showError(err)
            }
        },
        async searchCars(event) {
            const value = event.value
            this.carSearchData = value

            if (value?.length <= 2) {
                this.showCarMinCharsMessage = true
                if (value.length === 0 && this.selectedCustomer && this.customerCars) {
                    this.cars = this.customerCars
                } else {
                    this.cars = null
                }
                return false
            }

            this.clearCarSearchData = false
            // if (this.carSearchData && this.carSearchData.length) {
            this.carsDataIsLoading = true
            try {
                const { status, data } = await httpClient('car/search-cars?search=' + this.carSearchData)
                if (status === 200 && data) {
                    this.cars = data
                }
            } catch(err) {
                this.showError(err)
            } finally {
                this.showCarMinCharsMessage = false
                this.carsDataIsLoading = false
            }
            // } else {
            //     if (this.customerCars && this.customerCars.length) {
            //         this.cars = this.customerCars
            //     } else {
            //         this.cars = null
            //     }
            // }
        },
        closeCarModal() {
            this.carModal = false
            this.car = {}
            if (this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', false)
            }
        },
        async searchWarehouseItems(event, position) {
            let searchData = event.value
            if (searchData) {
                searchData = searchData.replace(' ','%20')
                try {
                    const { status, data } = await httpClient('warehouse/get-items?search=' + searchData)
                    if (status === 200 && data) {
                        position.warehouseItemsData = data
                    }
                } catch(err) {
                    this.showError(err)
                }
            } else {
                position.warehouseItemsData = this.warehouseItemsData
            }
        },
        async searchSuppliers(event, position) {
            let searchData = event.value
            if (searchData) {
                searchData = searchData.replace(' ','%20')
                try {
                    const { status, data } = await httpClient('supplier/get-suppliers?search=' + searchData)
                    if (status === 200 && data) {
                        position.suppliersData = data
                    }
                } catch(err) {
                    this.showError(err)
                }
            } else {
                position.suppliersData = this.suppliersData
            }
        },
        async searchServices(event, position) {
            let searchData = event.value
            if (searchData) {
                searchData = searchData.replace(' ','%20')
                try {
                    const { status, data } = await httpClient('service/get-services?expand=area&search=' + searchData)
                    if (status === 200 && data) {
                        position.servicesData = data
                    }
                } catch(err) {
                    this.showError(err)
                }
            } else {
                position.servicesData = this.servicesData
            }
        },
        addNewSupplier(position) {
            this.position = position
            this.supplier = {}
            this.supplierModal = true

            if (!this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', true)
            }
        },
        closeSupplierModal() {
            this.supplierModal = false
            this.supplier = {}
            if (this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', false)
            }
        },
        // updateSupplier(supplier, supplierExists = true) {
        updateSupplier(supplier) {
            if (!supplier) return false
            this.position.selectedSupplier = supplier
            const supplierInList = !!this.suppliersData?.find(i => i.id === supplier.id)
            if (supplier.isNew || !supplierInList) {
                this.suppliersData.push(supplier)
            }
            this.position.suppliersData = this.suppliersData
            this.position.searchData = null
            this.changeSupplier(this.position)
        },
        changeSupplier(position) {
            // position.isUpdated = !position.isNew
            const newSupplierId = position.selectedSupplier?.id
            position.supplier_id = newSupplierId

            if (position.originalSupplierId !== newSupplierId) {
                position.isUpdated = !position.isNew
                position.supplierChanged = [position.originalSupplier?.company_name, position.selectedSupplier?.company_name]
            } else {
                position.isUpdated = false
                position.supplierChanged = false
            }
            // this.findSelectedSupplier(position)
        },
        addNewWarehouseItem(position) {
            this.position = position
            this.warehouseItem = {}
            this.warehouseItemModal = true
            this.$store.commit('toggleSecondLayer', true)

           // this.getWarehouseCategories()
        },
        // async getWarehouseCategories() {
        //     try {
        //         const { data, status } = await httpClient('warehouse/get-categories?sort=sorting')
        //         if (status === 200 && data) {
        //             // this.warehouseCategories = Array.isArray(data) ? data : []
        //             this.warehouseCategories = data
        //         }
        //     } catch(err) {
        //         this.showError(err)
        //     }
        // },
        closeWarehouseItemModal() {
            this.warehouseItemModal = false
            this.warehouseItem = {}
            if (this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', false)
            }
        },
        updateWarehouseItem(warehouseItem) {
            if (!warehouseItem) return false
            this.position.selectedWarehouseItem = warehouseItem
            if (warehouseItem.isNew) {
                this.warehouseItemsData.push(warehouseItem)
            }
            this.position.warehouseItemsData = this.warehouseItemsData
            this.position.searchData = null
            this.changeWarehouseItem(this.position)
        },
        changeWarehouseItem(position) {
            position.isUpdated = !position.isNew
            if (position.selectedWarehouseItem) {
                position.is_decimal = !!position.selectedWarehouseItem.is_decimal
                position.qty = position.is_decimal ? this.formatDecimal(position.qty, false) : this.formatDecimal(position.qty, false, 0)
                position.code = position.selectedWarehouseItem.code
                position.raw_code = position.raw_code ? position.raw_code : this.generateRawCode(position.selectedWarehouseItem.code)
                position.name = position.selectedWarehouseItem.name
                position.purchase_price = position.selectedWarehouseItem.purchase_price ? +position.selectedWarehouseItem.purchase_price : 0
                position.sell_price = position.selectedWarehouseItem.sell_price ? +position.selectedWarehouseItem.sell_price : 0
                position.warehouse_item_id = position.selectedWarehouseItem.id
                // position.selectedWarehouseItem.qty -= position.qty
            } else {
                position.is_decimal = false
                position.code = null
                position.name = null
                position.purchase_price = null
                position.sell_price = null
                position.warehouse_item_id = null
            }

            this.calculatePositionSummary(position)
            this.updateMargin(position)
            // this.updateConsumable()
            this.updateSummary()
        },
        onRequestPositionStateClick(event, position, positionIsNew = false) {
            if (position.status === 0) {
                return false
            }
            if (positionIsNew) {
                this.$refs.newRequestStatesMenu.show(event);
            } else {
                this.$refs.requestStatesMenu.show(event);
            }

            this.positionToUpdate = position
        },
        findPositionsLastSortingValue() {
            if (!this.positions?.length) return 1
            let maxValue = 1;
            const values = Object.values(this.positions);
            values.map((el) => {
                //getting the value from each object and
                //comparing to existing value
                const valueFromObject = el.sorting;
                maxValue = Math.max(maxValue, valueFromObject);
            });
            if (maxValue < this.positions.length) {
                maxValue = this.positions.length
            }
            ++maxValue
            // console.log(maxValue)
            return maxValue
        },
        addNewPosition(type) {
            this.submitted = false
            const position = {
                type: type,
                name: '',
                qty: 1,
                discount: null,
                status: 1,
                isNew: true,
                // sorting: this.lastSortingNum++
                sorting: this.findPositionsLastSortingValue()
            }
            if (this.selectedCustomer) {
                if ((type === 1) && this.selectedCustomer.services_discount) {
                    position.discount = +this.selectedCustomer.services_discount
                } else if ((type === 2 || type === 3) && this.selectedCustomer.products_discount) {
                    position.discount = +this.selectedCustomer?.products_discount
                }
            }

            if (position.discount && +position.discount) {
                this.showDiscountColumn = true
            }

            // const defaultTax = this.taxesData?.filter(tax => tax.is_default)[0] ?? null
            // if (this.selectedCustomer?.tax_id) {
            //     position.tax_id = this.selectedCustomer.tax_id
            //     const selectedTax  = this.taxesData.filter(tax => tax.id === this.selectedCustomer.tax_id)[0]
            //     if (selectedTax) {
            //         position.selectedTax = selectedTax
            //     } else {
            //         position.selectedTax = defaultTax
            //     }
            // } else {
            //     position.selectedTax = defaultTax
            //     position.tax_id = defaultTax?.id
            // }

            this.pickTax(position)

            if (type === 2) {
                position.state = 1
            }

            if (type === 1 && this.selectedMasters && this.selectedMasters.length === 1) {
                position.selectedMaster = this.selectedMasters[0]
                position.master_id = this.selectedMasters[0].id
            }

            // position.history.created_at = +new Date()

            this.positions.push(position)
            // this.$toast.add({severity:'success', detail: 'Position added', life: this.settings.toastLife})
        },
        addMaintenancePositions() {
            // let serviceDiscount = null
            let partsDiscount = null
            if (this.selectedCustomer?.products_discount) {
                partsDiscount = +this.selectedCustomer?.products_discount
            }

            const positions = [
                {type: 2, name: 'Õlifilter', qty: 1},
                {type: 2, name: 'Õhufilter', qty: 1},
                {type: 2, name: 'Salongifilter', qty: 1},
                {type: 2, name: 'Kütusefilter', qty: 1},
                {type: 2, name: 'Mootoriõli 5l', qty: 1},
                {type: 2, name: 'Mootoriõli 1l', qty: 1},
                // {type: 2, name: 'Service 2.5t', qty: 1},
                // {type: 2, name: 'Õlikork', qty: 1},
                // {type: 1, name: 'Hooldus', qty: 1, discount: serviceDiscount, status: 1, isNew: true, sorting: this.lastSortingNum++},
            ]

            positions.forEach(position => {
                this.pickTax(position)
                if (position.type === 2) {
                    position.state = 1
                    position.status = 1
                    position.isNew = true
                    position.discount = partsDiscount
                    position.sorting = this.findPositionsLastSortingValue()
                    this.positions.push(position)
                }
            })
        },
        addSecondMaintenancePositions() {
            let partsDiscount = null
            if (this.selectedCustomer?.products_discount) {
                partsDiscount = +this.selectedCustomer?.products_discount
            }

            const positions = [
                {type: 2, name: 'Piduriklotsid ees', qty: 1},
                {type: 2, name: 'Piduriketas ees', qty: 2},
                {type: 2, name: 'Piduriklotsid taga', qty: 1},
                {type: 2, name: 'Piduriketas taga', qty: 2},
            ]

            positions.forEach(position => {
                this.pickTax(position)
                if (position.type === 2) {
                    position.state = 1
                    position.status = 1
                    position.isNew = true
                    position.discount = partsDiscount
                    position.sorting = this.findPositionsLastSortingValue()
                    this.positions.push(position)
                }
            })
        },
        pickTax(position) {
            // if (!item.selectedTax) return false
            if (position.selectedTax) {
                return false
            }
            // const defaultTax = this.taxesData?.find(tax => tax.is_default) ?? null
            // if (this.selectedCustomer?.tax_id) {
            //     const selectedTax  = this.taxesData.find(tax => tax.id === this.selectedCustomer.tax_id)
            //     position.selectedTax = selectedTax ?? defaultTax
            //     position.tax_id = this.selectedCustomer.tax_id
            //     position.tax_value = this.selectedCustomer.tax_value
            // } else {
            //     position.selectedTax = defaultTax
            //     position.tax_id = defaultTax?.id
            //     position.tax_value = defaultTax?.value
            // }

            // const selectedTax = this.selectedCustomer?.tax_id ? this.taxesData.find(tax => tax.id === this.selectedCustomer.tax_id) : null
            //
            // if (selectedTax) {
            //     position.selectedTax = selectedTax
            //     position.tax_id = selectedTax.id
            //     position.tax_value = selectedTax.value
            // } else {
            const defaultTax = this.taxesData?.find(tax => tax.is_default) ?? null
            if (defaultTax) {
                position.selectedTax = defaultTax
                position.tax_id = defaultTax.id
                position.tax_value = defaultTax.value
            }
            // }
            // console.log(position.tax_value)
        },
        toggleConsumable() {
            this.updateConsumable()
            this.updateSummary()
        },
        updateConsumable() {
            if (this.useConsumables) {
                const positionsWithConsumables = this.positions.filter(position => position.type === 1 && position.status === 1 && position.selectedService?.use_consumables) ?? null
                if (positionsWithConsumables?.length) {
                    if (!this.consumable) {
                        this.consumable = {
                            // id: this.itemData.consumable ? +this.itemData.consumable.id : null,
                            id: null,
                            name: this.$store.state.branchData.consumable_name,
                            qty: 1,
                            discount: null,
                        }
                    }

                    this.pickTax(this.consumable)

                    // let consumablesPurchasePrice = 0
                    let consumablesSellPrice = 0
                    let positionWithconsumablesQty = 0

                    if (positionsWithConsumables && positionsWithConsumables.length) {
                        positionWithconsumablesQty += positionsWithConsumables.reduce((sum, position) => {
                            return sum + position.qty
                        }, 0)
                        // consumablesPurchasePrice += positionsWithConsumables.reduce((sum, position) => {
                        //     return sum + (position.selectedService.consumables_purchase * position.qty)
                        // }, 0)
                        consumablesSellPrice += positionsWithConsumables.reduce((sum, position) => {
                            return sum + (position.selectedService.consumables_sell * position.qty)
                        }, 0)
                    }

                    if (this.consumable) {
                        // const qty = +this.consumable.qty
                        if (positionWithconsumablesQty === 0) {
                            this.consumable.qty = 0
                        } else {
                            this.consumable.qty = positionWithconsumablesQty < 0 ? -1 : 1
                        }
                        // this.consumable.qty = positionWithconsumablesQty < 0 ? -1 : 1
                        // this.consumable.purchase_price = consumablesPurchasePrice * qty

                        // const consumableSellPrice = consumablesSellPrice * qty
                        // const consumableSellPrice = consumablesSellPrice * positionWithconsumablesQty
                        const consumableSellPrice = consumablesSellPrice * this.consumable.qty
                        // this.consumable.sell_price = this.formatDecimal(consumableSellPrice, false)
                        this.consumable.sell_price = consumableSellPrice || 0
                        // this.consumable.sell_price = consumableSellPrice

                        // this.calculateConsumableSummary()
                        this.calculatePositionSummary(this.consumable)
                        // this.consumable.sum = (this.consumable.sell_price - (((this.consumable.discount || 0) / 100) * this.consumable.sell_price)) * this.consumable.qty
                        // this.consumable.total = ((this.consumable.sell_price - (((this.consumable.discount || 0) / 100) * this.consumable.sell_price))) * (((this.consumable.selectedTax ? this.consumable.selectedTax.value : 0) / 100) + 1) * this.consumable.qty
                    }
                } else {
                    this.consumable = null
                }
            } else {
                this.consumable = null
            }
        },
        pickConsumableTax() {
            // if (this.consumable && !this.consumable.selectedTax) {
            //     const defaultTax = this.taxesData.filter(tax => tax.is_default)[0] ?? null
            //     if (this.itemData.consumable && this.itemData.consumable.tax_id) {
            //         // console.log(this.itemData.consumable)
            //         // const filteredTax = this.taxesData.filter(tax => tax.id === this.itemData.consumable.tax_id)[0] ?? null
            //         //
            //         // this.consumable.selectedTax = filteredTax ?? defaultTax
            //         // this.consumable.tax_id = this.itemData.consumable.tax_id
            //
            //         this.findSelectedTax(this.itemData.consumable)
            //     } else if (this.selectedCustomer && this.selectedCustomer.tax_id) {
            //         const filteredTax = this.taxesData.filter(tax => tax.id === this.selectedCustomer.tax_id)[0] ?? null
            //         this.consumable.selectedTax = filteredTax ?? defaultTax
            //         this.consumable.tax_id = this.selectedCustomer.tax_id
            //     } else {
            //         this.consumable.selectedTax = defaultTax ?? null
            //         this.consumable.tax_id = defaultTax?.id
            //     }
            // }

            // if (!position.tax?.id) return false
            // const tax = this.taxesData.filter(tax => tax.id === position.tax.id)[0]
            // if (tax) {
            //     return tax
            // } else {
            //     this.taxesData.push(position.tax)
            //     return position.tax
            // }
        },
        // updateSummary() {
        //     let positionsSum
        //     let positionsTotal
        //
        //     if (this.positions && this.positions.length) {
        //         const activePositions = [...this.positions].filter(position => position.status === 1)
        //         positionsSum = activePositions.reduce((sum, position) => {
        //             return sum + this.formatDecimal(position.sum)
        //         }, 0)
        //
        //         positionsTotal = activePositions.reduce((sum, position) => {
        //             return sum + this.formatDecimal(position.total)
        //         }, 0)
        //     }
        //
        //     const countConsumables = this.useConsumables && this.consumable
        //     const sum = (positionsSum || 0)  + (countConsumables ? this.formatDecimal(this.consumable.sum) : 0)
        //     const total = (positionsTotal || 0) + (countConsumables ?  this.formatDecimal(this.consumable.total) : 0)
        //
        //     this.summary.sum = this.formatDecimal(sum)
        //     this.summary.grand_total = this.formatDecimal(total)
        //
        //     const tax = (this.summary.grand_total - this.summary.sum) || 0
        //
        //     this.summary.tax = this.formatDecimal(tax)
        // },
        updateSummary() {
            let positionsSum = 0
            let positionsTaxSum = 0
            // let positionsGrandTotal = 0

            if (this.positions?.length) {
                const activePositions = [...this.positions].filter(position => position.status === 1)
                // positionsSum = activePositions.reduce((sum, position) => {
                //     return sum + this.formatDecimal(position.sum, false)
                // }, 0)
                //
                // positionsTotal = activePositions.reduce((sum, position) => {
                //     return sum + this.formatDecimal(position.total, false)
                // }, 0)
                activePositions?.forEach(position => {
                    // const sum = this.formatDecimal(position.sum, false)
                    const sum = position.sum || 0
                    // const taxSum = this.formatDecimal(position.tax_sum, false)
                    const taxSum = position.tax_sum || 0
                    positionsSum += sum
                    positionsTaxSum += taxSum
                    // positionsGrandTotal += this.formatDecimal(position.total, false)
                    // positionsGrandTotal += sum + taxSum
                })
            }

            if (this.useConsumables && this.consumable) {
                // const sum = this.formatDecimal(this.consumable.sum, false)
                const sum = this.consumable.sum || 0
                // const taxSum = this.formatDecimal(this.consumable.tax_sum, false)
                const taxSum = this.consumable.tax_sum || 0
                positionsSum += sum
                positionsTaxSum += taxSum
                // positionsGrandTotal += this.formatDecimal(this.consumable.total)
                // positionsGrandTotal += sum + taxSum
            }

            this.summary.sum = positionsSum
            this.summary.tax = positionsTaxSum
            // this.summary.grand_total = positionsGrandTotal
            this.summary.grand_total = positionsSum + positionsTaxSum
        },
        changeService(position) {
            if (position.selectedService) {
                position.name = position.selectedService.name
                // position.purchase_price = +position.selectedService.purchase
                position.sell_price = +position.selectedService.sell
                position.service_id = position.selectedService.id

                // if (position.selectedService.area && !this.itemData.selectedArea) {
                //     // console.log(position.selectedService.area)
                //     this.selectedArea = position.selectedService.area
                // }
            } else {
                position.name = null
                position.sell_price = null
                position.discount = null
                position.service_id = null
            }


            // if (position.selectedArea && !this.itemData.selectedArea) {
            //     this.itemData.selectedArea = position.selectedArea
            // }

            // position.service_id = position.selectedService ? position.selectedService.id : null
            this.checkEmployeePaymentToDelete(position)

            // const newServices = this.positions?.filter(position => position.type === 1 && position.isNew)
            // const activePositions = this.positions?.filter(position => position.status === 1 && !position.isNew)
            
            // if ((!this.itemData.have_name || !this.itemData.name?.length) && (newServices && newServices.length === 1) && (!activePositions || !activePositions.length)) {
            // if ((!this.itemData.have_name || !this.itemData.name?.length) && (newServices && newServices.length === 1)) {
            //     this.itemData.have_name = false
            //     this.itemData.name = position.name
            // }

            const activeServices = this.positions?.filter(position => position.type === 1 && position.status)
            // if (activeServices?.length === 1 || !this.itemData.name?.length && !this.itemData.have_name) {
            //     this.itemData.name = position.name
            // }
            if ((!this.itemData.name?.length || !this.itemData.have_name) && activeServices?.length === 1) {
                this.itemData.name = position.name
            }


            // this.checkClearSelectedArea()

            // if (!this.itemData.name?.length && activeServices?.length === 1) {
            //     this.itemData.have_name = false
            //     this.itemData.name = position.name
            // }

            this.calculatePositionSummary(position)
            // this.updatePosition(position)

            // if (position.selectedService?.use_consumables) {
            this.updateConsumable()
            // }

            this.updateSummary()
        },
        // checkClearSelectedArea() {
        //     const activeServicesWithArea = this.positions?.filter(position => position.type === 1 && position.status && position.selectedService?.area)
        //     console.log('checkClearSelectedArea')
        //     if (!activeServicesWithArea?.length) {
        //         this.selectedArea = null
        //     }
        // },
        changeSelectedMasters() {
            this.updateMasters = true
            const servicePositions = this.positions?.filter(pos => pos.status && pos.type === 1)
            if (!servicePositions?.length) return false
            if (!this.selectedMasters || this.selectedMasters.length < 1) {
                // if (this.positions?.length) {
                servicePositions.forEach(position => {
                    position.selectedMaster = null
                    this.changeMaster(position)
                })
            } else if (this.selectedMasters?.length === 1) {
                servicePositions.forEach(position => {
                    position.selectedMaster = this.selectedMasters[0]
                    this.changeMaster(position)
                })
            } else {
                const actualMastersIds = this.selectedMasters.map(m => m.id)
                servicePositions.forEach(position => {
                    if (position.selectedMaster && actualMastersIds.indexOf(position.selectedMaster.id) < 0) {
                        position.selectedMaster = null
                        this.changeMaster(position)
                    }
                })
            }
        },
        changeMaster(position) {
            position.master_id = position.selectedMaster ? position.selectedMaster.id : null
            this.checkEmployeePaymentToDelete(position)
        },
        checkEmployeePaymentToDelete(position) {
            if (position.type !== 1) return false

            const paymentIndex = this.paymentsToDeleteIds.indexOf(position.id)

            if (position.originalMasterId !== position.master_id) {
                position.employeeChanged = [position.originalMaster?.full_name, position.selectedMaster?.full_name]
            } else {
                position.employeeChanged = false
            }

            if (position.originalMasterId !== position.master_id ||
                this.formatDecimal(position.originalQty, false) !== this.formatDecimal(position.qty, false) ||
                position.originalServiceId !== position.service_id ||
                position.originalSellPrice !== position.sell_price ||
                position.status === 2) {
                position.isUpdated = !position.isNew
                position.purchase_price = 0
                position.purchase_sum = 0
                // if (paymentIndex < 0 && this.itemData.have_payments) {
                if (paymentIndex < 0 && position.id) {
                    this.paymentsToDeleteIds.push(position.id)
                }
            } else {
                // position.isUpdated = false
                position.purchase_price = position.originalPurchasePrice
                position.purchase_sum = position.originalPurchaseSum
                if (paymentIndex >= 0) {
                    this.paymentsToDeleteIds.splice(paymentIndex, 1)
                }
            }
        },
        updateMargin(position) {
            if ((position.purchase_price && +position.purchase_price) && (position.sell_price && +position.sell_price)) {
                position.margin = this.calculatePositionMargin(position)
            } else {
                position.margin = null
            }
        },
        marginOnInput(position, value) {
            // console.log(value)
            // console.log('marginOnInput', position)
            // position.margin = position.sell_price ? this.calculatePositionMargin(position) : 0
            const discountSumForOneQty = position.discount_sum / position.qty
            // const sellPriceWithDiscount = position.purchase_price + discountSumForOneQty

            // const discountSum =

            if (discountSumForOneQty && +discountSumForOneQty) {
                return false
                // position.sell_price = (position.purchase_price + discountSumForOneQty) + ((discountSumForOneQty + position.purchase_price) * (value / 100))
            } else {
                if (position.purchase_price && +position.purchase_price !== 0) {
                    position.sell_price = position.purchase_price + (position.purchase_price * (value / 100))
                } else {
                    position.sell_price = null
                }

            }
            this.updatePosition(position, value, 'margin')

            // const qty = position.qty ?? 0
            // const purchasePrice = position.purchase_price ?? 0
            // const sellPrice = position.sell_price ?? 0
            // const discount = position.discount ?? 0
            // const taxValue = position.selectedTax ? position.selectedTax.value : 0
            //
            // const positionPurchaseSum = purchasePrice * qty
            // const positionSum = (sellPrice - ((discount / 100) * sellPrice)) * qty //sum with discount
            // const positionTaxSum = positionSum * (taxValue / 100)
            // const positionDiscountSum = (sellPrice * qty) - positionSum
            // // const positionTotal = positionSum + positionTaxSum
            //
            // // position.purchase_sum = this.formatDecimal(positionPurchaseSum, false)
            // position.purchase_sum = positionPurchaseSum
            // position.sum = this.formatDecimal(positionSum, false)
            //
            // // position.tax_sum = this.formatDecimal(positionTaxSum, false)
            // // position.discount_sum =  this.formatDecimal(positionDiscountSum, false)
            // // position.total = this.formatDecimal(position.sum + position.tax_sum, false)
            //
            // position.tax_sum = +positionTaxSum.toFixed(2)
            // position.discount_sum =  +positionDiscountSum.toFixed(2)
            // position.total = +(position.sum + position.tax_sum).toFixed(2)
        },
        updatePosition(position, value, param, maxValue = null, minValue = null, isOffer = false) {
            // console.log(position.status)
            if (value && ((maxValue || maxValue === 0) && value > maxValue)) {
                value = maxValue
            }

            if (value && ((minValue || minValue === 0) && value < minValue)) {
                value = minValue
            }

            if (value && minValue === 0 && value === '-') {
                value = null
            }

            position.isUpdated = !position.isNew && !position.isReduced

            if (param === 'name') {
                return false
            }

            if (param === 'code') {
                position.raw_code = this.generateRawCode(position.code)
                return false
            }

            if (param === 'discount') {
                if (value > 100) {
                    value = 100
                } else if (value < 0) {
                    value = 0
                } else if (value === '-') {
                    value = 0
                }
            }

            if (param === 'qty') {
                if (value === '-') {
                    value = null
                }
            }

            position[param] = value

            if (param === 'qty' && position.type === 1) {
                this.checkEmployeePaymentToDelete(position)
            }

            if (param === 'purchase_price' && position.type === 2) {
                // if (position.sell_price_fixed) return false //Uncomment this line to not chanage sell price automatically if position is not new
                if (value && !isOffer && this.$store.state.branchData?.orders_margin) {
                    const margins = JSON.parse(this.$store.state.branchData.orders_margin)
                    const margin = margins?.find(margin => {
                        return margin.from <= value && margin.to >= value
                    })
                    const marginValue = margin ? margin.value : this.$store.state.branchData.orders_default_margin
                    position.sell_price = marginValue ? value * ((marginValue / 100) + 1) : 0
                } else if (value && isOffer && this.$store.state.branchData?.offers_margin) {
                    const margins = JSON.parse(this.$store.state.branchData.offers_margin)
                    const margin = margins?.find(margin => {
                        return margin.from <= value && margin.to >= value
                    })
                    const marginValue = margin ? margin.value : this.$store.state.branchData.offers_default_margin
                    position.sell_price = marginValue ? value * ((marginValue / 100) + 1) : 0
                } else {
                    position.sell_price = null
                }
            }

            this.calculatePositionSummary(position)

            if (position.type === 1) {
                this.updateConsumable()
            }

            if (param !== 'margin') {
                this.updateMargin(position)
            }

            this.updateSummary()
        },
        // calculatePositionSummary(position) {
        //     const qty = position.qty ?? 0
        //     const purchasePrice = position.purchase_price ?? 0
        //     const sellPrice = position.sell_price ?? 0
        //     const discount = position.discount ?? 0
        //     const taxValue = position.selectedTax ? position.selectedTax.value : 0
        //
        //     position.sum = (sellPrice - ((discount / 100) * sellPrice)) * qty
        //     position.total = ((sellPrice - ((discount / 100) * sellPrice)) * qty) * ((taxValue / 100) + 1)
        //
        //     position.purchase_sum = purchasePrice * qty
        //     position.discount_sum = (sellPrice * qty) - position.sum
        //     position.tax_sum = position.total - position.sum
        // },
        // calculatePositionSummary(position) {
        //     const qty = position.qty ?? 0
        //     const purchasePrice = position.purchase_price ?? 0
        //     const sellPrice = position.sell_price ?? 0
        //     const discount = position.discount ?? 0
        //     const taxValue = position.selectedTax ? position.selectedTax.value : 0
        //
        //
        //     position.purchase_sum = purchasePrice * qty
        //     position.sum = (sellPrice - ((discount / 100) * sellPrice)) * qty //sum with discount
        //
        //     // console.log(sellPrice - ((discount / 100) * sellPrice))
        //     position.tax_sum = position.sum * (taxValue / 100)
        //     // position.total = ((sellPrice - ((discount / 100) * sellPrice)) * qty) * ((taxValue / 100) + 1)
        //     position.discount_sum = (sellPrice * qty) - position.sum
        //     position.total = position.sum + position.tax_sum
        // },
        calculatePositionSummary(position) {
            const qty = position.qty ?? 0
            const purchasePrice = position.purchase_price ?? 0
            const sellPrice = position.sell_price ?? 0
            const discount = position.discount ?? 0
            const taxValue = position.selectedTax ? position.selectedTax.value : 0

            const positionPurchaseSum = purchasePrice * qty
            const positionSum = (sellPrice - ((discount / 100) * sellPrice)) * qty //sum with discount
            const positionTaxSum = positionSum * (taxValue / 100)
            const positionDiscountSum = (sellPrice * qty) - positionSum
            // const positionTotal = positionSum + positionTaxSum

            // position.purchase_sum = this.formatDecimal(positionPurchaseSum, false)
            position.purchase_sum = positionPurchaseSum
            position.sum = this.formatDecimal(positionSum, false)

            // position.tax_sum = this.formatDecimal(positionTaxSum, false)
            // position.discount_sum =  this.formatDecimal(positionDiscountSum, false)
            // position.total = this.formatDecimal(position.sum + position.tax_sum, false)

            position.tax_sum = +positionTaxSum.toFixed(2)
            position.discount_sum =  +positionDiscountSum.toFixed(2)
            position.total = +(position.sum + position.tax_sum).toFixed(2)
        },
        changeTax(position, newTax) {
            position.isUpdated = !position.isNew

            if (position.sell_price) {
                position.selectedTax = newTax
                position.tax_id = newTax.id
                position.tax_value = newTax.value
                this.calculatePositionSummary(position)
            }
            this.updateSummary()
        },
        // changeConsumableDiscount(value) {
        //     // if (!value) return false
        //     this.consumable.discount = value
        //
        //     this.calculateConsumableSummary()
        //     this.updateSummary()
        // },
        // changeConsumableTax(tax) {
        //     this.consumable.selectedTax = tax.value
        //     this.consumable.tax_id = tax.value.id
        //     this.consumable.tax_value = tax.value.value
        //
        //     this.calculateConsumableSummary()
        //     this.calculatePositionSummary()
        //     this.updateSummary()
        // },
        // calculateConsumableSummary() {
        //     if (!this.consumable || !this.useConsumables) return false
        //
        //     const qty = this.consumable.qty ?? 0
        //     const purchasePrice = this.consumable.purchase_price ?? 0
        //     const sellPrice = this.consumable.sell_price ?? 0
        //     const discount = this.consumable.discount ?? 0
        //     const taxValue = this.consumable.selectedTax ? this.consumable.selectedTax.value : 0
        //
        //     this.consumable.purchase_sum = purchasePrice * qty
        //     this.consumable.sum = (sellPrice - ((discount / 100) * sellPrice)) * qty //sum with discount
        //
        //     this.consumable.tax_sum = this.consumable.sum * (taxValue / 100)
        //     this.consumable.discount_sum = (sellPrice * qty) - this.consumable.sum
        //     this.consumable.total = this.consumable.sum + this.consumable.tax_sum
        // },
        removePosition(index) {
            this.positions.splice(index, 1)
            this.updateConsumable()
            this.updateSummary()
            // this.checkClearSelectedArea()
        },
        // reduceRequestPositionOnClick(positionToReduce) {
        //     this.positionToReduce = positionToReduce
        //     this.reduceRequestPositionModal = true
        //     this.$store.commit('toggleSecondLayer', true)
        // },
        // async reduceRequestPosition(returnPosition, qtyToReturn, orderState) {
        //     if (orderState) {
        //         this.itemData.state = orderState
        //         const state = OrderStates.filter(state => state.num === orderState)[0]
        //         if (state) {
        //             this.itemData.selectedState = state
        //         }
        //     }
        //
        //     const historyData = {
        //         returned: qtyToReturn,
        //         reason: returnPosition.reason,
        //         qty: [this.positionToReduce.qty, this.positionToReduce.qty - qtyToReturn]
        //     }
        //
        //     this.positionToReduce.history.push({
        //         created_at: +new Date() / 1000,
        //         creator: { first_name: this.$store.state.user.first_name, last_name: this.$store.state.user.last_name },
        //         data: JSON.stringify(historyData)
        //     })
        //
        //     this.positionToReduce.qty -= qtyToReturn
        //     this.positionToReduce.isReduced = true
        //
        //     if (+this.positionToReduce.qty === 0) {
        //         this.positionToReduce.status = 0
        //         if (!this.deletedPositions) {
        //             this.deletedPositions = true
        //         }
        //         this.positionToReduce.deleted_at = +new Date() / 1000,
        //             this.positionToReduce.deleted_by = { first_name: this.$store.state.user.first_name, last_name: this.$store.state.user.last_name }
        //     }
        //     // else {
        //         // const state = OrderStates.filter(state => state.num === serverResponseData[2])[0]
        //         // if (state?.length) {
        //         //   this.itemData.selectedState = state
        //         // }
        //     // }
        //     this.updatePosition(this.positionToReduce)
        //     this.updateSummary()
        //     this.$emit('update-items', this.itemData.id)
        // },
        // closeReduceRequestPositionModal() {
        //     this.positionToReduce = {}
        //     this.reduceRequestPositionModal = false
        //     if (this.$store.state.secondLayerIsOpened) {
        //         this.$store.commit('toggleSecondLayer', false)
        //     }
        // },
        confirmPositionDelete(positionToDelete) {
            this.positionToDelete = positionToDelete
            this.confirmPositionDeleteModal = true
            if (!this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', true)
            }
            // this.checkClearSelectedArea()
        },
        closeConfirmPositionDeleteModal() {
            this.positionToDelete = {}
            this.confirmPositionDeleteModal = false
            if (this.$store.state.secondLayerIsOpened) {
                this.$store.commit('toggleSecondLayer', false)
            }
        },
        close() {
            this.$emit('close')
            // this.itemData.orderWasUpdatedByThirdParty = false
            // this.customerDisabled = false
            // this.carDisabled = false
            // this.editingIsDisabled = false
        },
        // calculatedMinQty(type) {
        //     if (type === 2 || type === 3) {
        //         return 0
        //     } else {
        //         return -999
        //     }
        // },
    },
    computed: {
        showMarginColumn() {
            if (this.showDeleted) {
                return !!(this.positions?.find(position => position.type !== 1))
            } else {
                return !!(this.positions?.find(position => position.status && position.type !== 1))
            }
             //Exclude service position
        },
        isAnyPositionWithDiscount() {
            return (this.positions?.find(p => p.discount && +p.discount !== 0) || (this.consumable?.discount && +this.consumable?.discount !== 0))
        },
        isAnyActiveServicePosition() {
            return !!this.positions?.find(p => p.status && p.type === 1)
        },
        showPositionsTable() {
            return (!this.noPositions || this.showDeleted) || (this.useConsumables && this.consumable)
        },
        // computedPositions() {
        //     const positions = this.positions?.filter(pos => pos.type !== 5)
        //     return positions ?? false
        // },
        // computedConsumable() {
        //     const consumable = this.positions?.filter(pos => pos.type == 5)?.[0]
        //     return consumable ?? false
        // },
        computedServicesTime() {
            if (!this.positions) return 0
            const activeServicePositions = this.positions.filter(p => p.status && p.type === 1)
            if (activeServicePositions?.length) {
                let totalTime = 0
                activeServicePositions.forEach(pos => {
                    const serviceTime = pos.selectedService?.time ?? 0
                    totalTime += +serviceTime * pos.qty
                })
                return totalTime
            } else {
                return 0
            }
        },
        computedDeletedPositionsExists() {
            return !!this.positions.find(pos => pos.status === 0)
        },
        noPositions() {
            return !this.positions.find(position => position.status)
        },
    }
}


export default modalWithPositionsMixins